import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FlexBox } from 'atoms/Box';
import * as NumberItems from 'components/input/Number';
import * as SelectItems from 'components/input/Select';
import * as cPPD from 'components/projectPriceDetail/Index';
import { MstDataContext } from 'contexts/Mst';
import { useContext } from 'react';
import { tProjectPriceDetail } from 'types/project';
import { tObjectListReturn } from 'types/useReducer';

// 経費を入力するモーダルのコンテンツ
interface MainProps {
  values: tObjectListReturn<tProjectPriceDetail>;
}

const Main = ({ values }: MainProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  /**
   * 単価計算用イベントハンドラー
   * @param index
   * @param unit
   * @param quantity
   * @param price
   */
  const handleChangePriceInfo = (
    index: number,
    unit: number | undefined | null,
    quantity: number | undefined | null,
    price: number | undefined | null
  ) => {
    console.log('handleChangePriceInfo', unit, quantity, price);
    values.updateItem(index, {
      price_unit: unit || null,
      quantity: quantity || null,
      price: price || null,
    });
  };

  return (
    <TableContainer sx={{ overflow: 'auto' }}>
      <Typography>金額明細</Typography>
      <Table sx={{ minWidth: 680, width: '100%' }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>区分</TableCell>
            <TableCell width="50%">金額</TableCell>
            <TableCell>税率</TableCell>
            <TableCell>備考</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.list.map((price, index) => (
            <TableRow key={index}>
              <TableCell>
                <cPPD.KbnLabel value={price.kbn} />
              </TableCell>
              <TableCell>
                {price.kbn === SYSTEM?.project.price_detail.detail ? (
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <FlexBox gapSize={0.5}>
                        <NumberItems.Quantity
                          label="数量/重量"
                          name="quantity"
                          unit={price.price_unit}
                          quantity={price.quantity}
                          price={price.price}
                          flgFloat={true}
                          flgInnerLabel={true}
                          cbValueChange={(unit, quantity, price) => {
                            console.log('Quantity', unit, quantity, price);
                            handleChangePriceInfo(index, unit, quantity, price);
                          }}
                        />
                      </FlexBox>
                    </Grid>
                    <Grid item xs={1} justifyItems={'center'}>
                      <Typography>×</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <NumberItems.PriceUnit
                        label="単価"
                        name="price_unit"
                        unit={price.price_unit}
                        quantity={price.quantity}
                        price={price.price}
                        flgFloat={true}
                        flgInnerLabel={true}
                        cbValueChange={(unit, quantity, price) => {
                          console.log('PriceUnit', unit, quantity, price);
                          handleChangePriceInfo(index, unit, quantity, price);
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} justifyItems={'center'}>
                      <Typography>=</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <NumberItems.Price
                        label=""
                        name="price"
                        unit={price.price_unit}
                        quantity={price.quantity}
                        price={price.price}
                        cbValueChange={(unit, quantity, price) => {
                          console.log('Price', unit, quantity, price);
                          handleChangePriceInfo(index, unit, quantity, price);
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <NumberItems.Main
                    label=""
                    value={price.price}
                    cbValueChange={(val) => {
                      if (val) {
                        values.updateItem(index, {
                          price: val,
                          quantity: 1,
                          price_unit: val,
                        });
                      } else {
                        values.updateItem(index, {
                          price: null,
                          quantity: null,
                          price_unit: null,
                        });
                      }
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <SelectItems.Tax
                  label=""
                  value={price.tax_id || null}
                  cbValueChange={(val) =>
                    values.updateItem(index, { tax_id: val })
                  }
                />
              </TableCell>
              <TableCell>
                <cPPD.Memo
                  label=""
                  value={price.memo}
                  cbValueChange={(val: string) =>
                    values.updateItem(index, { memo: val })
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Main;
