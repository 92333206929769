import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { FlexBox } from 'atoms/Box';
import { SmallTextField } from 'atoms/TextField';
import { NoSelect } from 'const';
import React, { useCallback, useEffect, useState } from 'react';
import { ItemAutoCompleteProps, ListItem } from 'types';

/**
 * オートコンプリートアイテム
 * @param param0
 * @returns
 */
const AutoCompleteItem: React.FC<ItemAutoCompleteProps> = ({
  label = '',
  name = '',
  value = NoSelect,
  items = [],
  freeSolo = false,
  flgNoSelect = false,
  flgCode = false,
  cbValueChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<ListItem>(NoSelect);

  useEffect(() => {
    if (!value) {
      setSelectedValue(NoSelect);
      return;
    }

    const selectedOption = items.find((item) => item.id === value.id);

    if (selectedOption) {
      setSelectedValue(selectedOption);
    } else {
      setSelectedValue(value);
    }
  }, [value, items]);

  const handleIdChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedOption = items.find(
        (item) => item.id === Number(e.target.value)
      );
      if (selectedOption !== undefined) {
        setSelectedValue(selectedOption);
        cbValueChange(selectedOption);
      } else {
        const val = {
          id: e.target.value ? Number(e.target.value) : null,
          label: '',
        };
        setSelectedValue(val);
        cbValueChange(val);
      }
    },
    [items, selectedValue, cbValueChange]
  );

  const handleLabelChange = useCallback(
    (event: React.SyntheticEvent, newValue: string | ListItem | null) => {
      let newItem: ListItem;
      if (typeof newValue === 'string') {
        newItem = { id: null, label: newValue };
      } else {
        newItem = newValue || NoSelect;
      }

      if (newItem !== selectedValue) {
        setSelectedValue(newItem);
        cbValueChange(newItem);
      }
    },
    [selectedValue, cbValueChange]
  );

  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <FlexBox gapSize={1}>
        {flgCode && (
          <SmallTextField
            label=""
            props={{
              name: `${name}-text`,
              value: selectedValue?.id || '',
              sx: { width: '100px' },
              onChange: handleIdChange,
            }}
          />
        )}
        <Autocomplete
          size="small"
          fullWidth
          freeSolo={freeSolo}
          disableClearable={!flgNoSelect}
          options={items}
          value={selectedValue}
          onChange={handleLabelChange}
          onInputChange={(event, newInputValue) => {
            if (freeSolo) {
              const selected = items.find((item) => {
                return item.label === newInputValue;
              });

              const newItem = {
                id: selected?.id || null,
                label: newInputValue,
              };
              cbValueChange(newItem);
              setSelectedValue(newItem);
            } else if (newInputValue === '') {
              // 手入力不可でも全消しは対応
              cbValueChange(NoSelect);
              setSelectedValue(NoSelect);
            }
          }}
          renderInput={(params) => (
            <SmallTextField
              label=""
              props={{
                ...params,
                name: `${name}-autocomplete`,
                value: selectedValue?.label || '',
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id || option.label}>
              {option.label}
            </li>
          )}
          limitTags={5}
        />
      </FlexBox>
    </>
  );
};

export default React.memo(AutoCompleteItem, (prevProps, nextProps) => {
  return (
    prevProps.value.id === nextProps.value.id &&
    prevProps.value.label === nextProps.value.label &&
    prevProps.items === nextProps.items &&
    prevProps.freeSolo === nextProps.freeSolo &&
    prevProps.flgNoSelect === nextProps.flgNoSelect &&
    prevProps.flgCode === nextProps.flgCode
  );
});
