import { APP_ENV } from 'functions/env';
import log from 'functions/logger';

export function Console_log(label: string, data: any): void {
  if (APP_ENV() != 'production') {
    log.debug(label);
    log.debug(data);
  }
}

export function getGridSize(size: Record<string, number> | null | undefined) {
  let xs = 12;
  let sm = 6;
  let md = 4;
  let lg = 3;
  let xl = 2;
  if (size?.xs) {
    xs = size.xs;
  }
  if (size?.sm) {
    sm = size.sm;
  }
  if (size?.md) {
    md = size.md;
  }
  if (size?.lg) {
    lg = size.lg;
  }
  if (size?.xl) {
    xl = size.xl;
  }
  return [xs, sm, md, lg, xl];
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function toHalfWidth(str: string): string {
  return (
    str
      .replace(/[！-～]/g, (s: string) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      })
      // eslint-disable-next-line no-irregular-whitespace
      .replace(/　/g, ' ')
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s: string) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      })
  );
}

/**
 * 文字列を数値に変換する
 * @param value
 * @returns number | null
 */
export const toNumber = (value: string): number | null => {
  try {
    const num = Number(value);
    return isNaN(num) ? null : num;
  } catch (e) {
    return null;
  }
};

/**
 * 3桁区切りにする関数
 * 少数がオンの時は2桁まで表示
 * @param numStr
 * @param flgFloat
 * @returns
 */
export const formatNumber = (numStr: string | number, flgFloat = true) => {
  let num: number | null;

  if (typeof numStr === 'number') {
    num = numStr;
  } else {
    num = toNumber(numStr);
    if (num === null) return ''; // 変換不可なら空文字を返す
  }

  // 少数2桁フォーマット（四捨五入）
  numStr = flgFloat ? num.toFixed(2) : Math.trunc(num).toString();

  const parts = numStr.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return flgFloat
    ? `${parts[0]}.${(parts[1] || '00').padEnd(2, '0')}`
    : parts[0];
};

/**
 * 文字列を指定文字数で省略する
 * @param str
 * @param maxLength
 * @returns
 */
export function truncateString(str: string, maxLength: number): string {
  try {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + '...';
  } catch (e) {
    return '';
  }
}

/**
 * 連想配列のキーでグループ化する
 * @param array
 * @param key
 * @returns
 */
export function groupBy<T>(array: T[], key: keyof T): { [key: string]: T[] } {
  return array.reduce(
    (result, item) => {
      const groupKey = item[key] as string;

      if (!result[groupKey]) {
        result[groupKey] = [];
      }

      result[groupKey].push(item);

      return result;
    },
    {} as { [key: string]: T[] }
  );
}

/**
 * オブジェクトが空かどうかを判定する
 * @param obj
 * @returns
 */
export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
