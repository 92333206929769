import SYSTEM from 'const/SYSTEM/index';
import { tCustomInvoiceEdit, tInvoice, tInvoiceDetail } from 'types/invoice';

export const defSheetName = 'type01';

export const sheetList = [defSheetName];

export const initInvoice: tInvoice = {
  id: undefined,
  invoice_number: '',
  title: '',
  print_date: null,
  price: 0,
  tax: 0,
  tax_exempt: 0,
  tax_adjust: 0,
  amount: 0,
  detail_count: 0,
  c_id: undefined,
  c_invoice_no: '',
  c_name: '',
  c_postal_code: '',
  c_address1: '',
  c_address2: '',
  p_id: undefined,
  p_name: '',
  details: [],
};

export const initInvoiceDetail: tInvoiceDetail = {
  id: undefined,
  iv_id: undefined,
  order: 0,
  pj_id: undefined,
  ctt_id: undefined,
  ctt_name: '',
  sheet_name: defSheetName,
  date: '',
  p_name: '',
  vehicle_name: '',
  trailer_name: '',
  container_code: '',
  load_name: '',
  unload_name: '',
  luggage_name: '',
  weight: 0,
  price_unit: 0,
  quantity: 0,
  price: 0,
  price_separate: 0,
  price_other: 0,
  price_details: [],
  memo: '',
  tradeType: null,
};

export const initCustomInvoiceEdit: tCustomInvoiceEdit = {
  id: undefined,
  status: SYSTEM.invoice.custom.status.edit.id,
  order: 0,
  date: '',
  p_name: '',
  vehicle_name: '',
  trailer_name: '',
  load_name: '',
  unload_name: '',
  luggage_name: '',
  weight: undefined,
  price_unit: undefined,
  quantity: undefined,
  price: undefined,
  price_separate: undefined,
  price_other: undefined,
  memo: '',
};
