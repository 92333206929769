import { tTransport, tTransportCard } from 'types/transport';

const initTransport: tTransport = {
  id: undefined,
  pj_id: undefined,
  no: undefined,
  status: undefined,
  container_code: '',
};

export default initTransport;

export const initialTransportCard: tTransportCard = {
  id: undefined, // tTransport['id'] の型に基づいて適切なデフォルト値を設定（文字列の場合は空文字）
  pj_id: undefined,
  tran_no: undefined,
  tran_status: undefined,
  status: 0,
  container_code: '',
  kind: 0,
  kind_name: '',
  c_id: undefined,
  company_name: '',
  company_abbreviation: '',
  load_datetime: '', // 日付の初期値は空文字列
  load_a_id: undefined,
  load_name: '',
  load_abbreviation1: '',
  load_abbreviation2: '',
  unload_datetime: '',
  unload_a_id: undefined,
  unload_name: '',
  unload_abbreviation1: '',
  unload_abbreviation2: '',
  price: 0,
  sum_price_other: 0,
  waypoint_count: 0,
  warning_level: 0,
  vehicle_name: '',
  trailer_name: '',
  waypoints: [],
};
