import { PrimaryButton } from 'atoms/Button';
import ErrModal from 'components/error/Modal';
import { InvoicePreview } from 'components/invoice/Button';
import { storeInvoice } from 'functions/api/invoice';
import { useState } from 'react';
import { tErrMsg } from 'types/index';
import { tInvoice, tInvoiceDetail } from 'types/invoice';

interface ClosingProps {
  iv: tInvoice;
  ivd: tInvoiceDetail[];
  callbackSuccess?: (data: tInvoice) => void;
  callbackPDFClose?: () => void;
}
export const Closing = ({
  iv,
  ivd,
  callbackSuccess,
  callbackPDFClose,
}: ClosingProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const [ivId, setIvId] = useState<number | undefined>(undefined);
  const [showPdf, setShowPdf] = useState(false);

  /**
   * 締め処理ボタンクリック時の処理
   */
  const handleClick = () => {
    console.log('Closing.tsx handleClick', [iv]);
    // 明細はフラグが立っているものだけを対象にする
    const ivdFiltered = ivd.filter((detail) => detail.flg === true);

    if (ivdFiltered.length === 0) {
      alert('明細がありません。');
      return;
    }

    // 不要なデータを削除
    const ivdPostData = ivdFiltered.map((obj: tInvoiceDetail) => ({
      pj_id: obj.pj_id,
      load_name: obj.load_name,
      unload_name: obj.unload_name,
      memo: obj.memo,
    }));

    const callbackStore = (data: tInvoice) => {
      if (!data.id) {
        alert('請求書の作成に失敗しました。');
        return;
      }

      setIvId(data.id);

      // 請求書を発行するかを確認
      if (
        window.confirm(
          '請求書データ作成に成功しました。¥nこのまま請求書を発行しますか？'
        )
      ) {
        setShowPdf(true);
      } else {
        setShowPdf(false);
      }
      if (callbackSuccess) {
        callbackSuccess(data);
      }
    };

    const callbackError = () => {
      alert('請求書の作成に失敗しました。');
    };

    const params = {
      invoice: iv,
      details: ivdPostData,
    };

    storeInvoice(params, callbackStore, callbackError);
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      {showPdf && (
        <InvoicePreview
          id={ivId}
          defFlgShow={true}
          callbackClose={() => {
            setShowPdf(false);
            if (callbackPDFClose) {
              callbackPDFClose();
            }
          }}
        />
      )}
      <PrimaryButton onClick={handleClick} label="締め処理" />
    </>
  );
};
