import Loading from 'atoms/Loading';
import {
  AutocompleteTextField,
  RowTextField,
  SmallTextField,
} from 'atoms/TextField';
import { MstDataContext } from 'contexts/Mst';
import React from 'react';
import {
  ItemRowTextFieldProps,
  ItemTextFieldProps,
  List,
  ListItem,
} from 'types';

export const Main = ({
  label = '',
  name = '',
  value,
  cbValueChange,
  flgInnerLabel = false,
}: ItemTextFieldProps) => {
  return (
    <SmallTextField
      label={label}
      innerLabel={flgInnerLabel}
      props={{
        value: value,
        name: name,
        onChange: (e) => cbValueChange(e.target.value),
      }}
    />
  );
};

/**
 * 積地
 * @param param0
 * @returns
 */
export const LoadName = ({
  label = '積地',
  name,
  value,
  cbValueChange,
}: ItemTextFieldProps) => {
  const { loading, addresses } = React.useContext(MstDataContext);

  if (loading || !addresses) {
    return <Loading flg={true} />;
  }

  // addressesからflg_loadがtrueのものをListItem[]の形で取得
  const options = addresses
    ?.filter((address) => address.flg_load)
    .map((address) => ({
      id: address.id,
      label: address.name,
    })) as List;

  const selectedOption = options?.find((option) => String(option.id) === value);
  return (
    <>
      <AutocompleteTextField
        freeSolo={true}
        label={label}
        options={options}
        value={selectedOption || null}
        inputValue={value}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          newValue: ListItem
        ) => {
          cbValueChange(newValue.label);
        }}
      />
    </>
  );
};

/**
 * コンテナ番号
 * @param param0
 * @returns
 */
export const ContainerCode = ({
  label = 'コンテナ番号',
  name = 'container_code',
  value,
  cbValueChange,
}: ItemTextFieldProps) => {
  return (
    <SmallTextField
      label={label}
      props={{
        value: value,
        name: name,
        onChange: (e) => cbValueChange(e.target.value),
      }}
    />
  );
};

export const Freeword = ({
  label = 'フリーワード',
  name = 'freeword',
  value,
  cbValueChange,
}: ItemTextFieldProps) => {
  return (
    <RowTextField
      label={label}
      value={value}
      name={name}
      handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
        cbValueChange(e.target.value)
      }
    />
  );
};

/**
 * 名称
 * @param param0
 * @returns
 */
export const Name = ({
  label = '名称',
  name = 'name',
  value,
  cbValueChange,
}: ItemTextFieldProps) => {
  return (
    <SmallTextField
      label={label}
      props={{
        value: value,
        name: name,
        onChange: (e) => cbValueChange(e.target.value),
      }}
    />
  );
};

/**
 * 略称
 * @param param0
 * @returns
 */
export const Abbreviation = ({
  label = '略称',
  name = 'abbreviation',
  value,
  cbValueChange,
}: ItemTextFieldProps) => {
  return (
    <SmallTextField
      label={label}
      props={{
        value: value,
        name: name,
        onChange: (e) => cbValueChange(e.target.value),
      }}
    />
  );
};

/**
 * 名称
 * @param param0
 * @returns
 */
export const Memo = ({
  label = '備考',
  name = 'memo',
  value,
  cbValueChange,
  row = 4,
}: ItemRowTextFieldProps) => {
  return (
    <RowTextField
      label={label}
      value={value}
      name={name}
      handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
        cbValueChange(e.target.value)
      }
      row={row}
    />
  );
};
