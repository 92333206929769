import { groupBy } from 'functions/array';
import { tInvoiceDetail, tInvoiceItem } from 'types/invoice';
import { tSYSTEM } from 'types/system';

/**
 * 請求書明細から金額明細を消費税区分別に分割する
 * @param items
 * @returns
 */
export const aryPriceForTax = (items: tInvoiceDetail[]) => {
  // priceDetailsを取得(flatMapは配列を平坦化する)
  const priceDetails = items.flatMap((item) => item.price_details || []);

  console.log('aryPriceForTax', priceDetails);

  // 税率区分別に配列を分割
  return groupBy(priceDetails, 'tax_id');
};

export const priceDiffTax = (item: tInvoiceItem, systemTax: tSYSTEM['tax']) => {
  const taxList = systemTax.list;

  // taxListの数だけidをキーにした配列を作成
  return taxList.reduce((acc: { [key: number]: number }, tax) => {
    switch (tax.id) {
      case systemTax['10'].id:
        acc[tax.id] = item.price_tax10;
        if (item.ctt_tax_id === tax.id) {
          acc[tax.id] += item.ctt_price_month || 0;
        }
        break;
      case systemTax['8'].id:
        acc[tax.id] = item.price_tax08;
        if (item.ctt_tax_id === tax.id) {
          acc[tax.id] += item.ctt_price_month || 0;
        }
        break;
      case systemTax.exempt.id:
        acc[tax.id] = item.price_tax00;
        if (item.ctt_tax_id === tax.id) {
          acc[tax.id] += item.ctt_price_month || 0;
        }
        break;
    }

    return acc;
  }, {});
};
