import { API_URL, create } from 'functions/axios';
import log from 'functions/logger';
import {
  apiDeleteProps,
  apiGetListSuccess,
  apiGetShowProps,
  apiListProps,
  tOrder,
} from 'types/index';
import {
  tCustomInvoiceGroupCId,
  tDetailsInfo,
  tInvoice,
  tInvoiceDetail,
} from 'types/invoice';

/**
 * 請求書登録
 * @param params
 * @returns
 */
export function storeInvoice(
  params: any,
  callbackSuccess?: (data: tInvoice) => void,
  callbackError?: () => void
) {
  const ins = create();
  return ins
    .post(API_URL.base + '/invoice/store', params)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの登録に失敗しました');
      }
      log.debug('storeInvoice', res.data);
      if (callbackSuccess) callbackSuccess(res.data);
    })
    .catch((err) => {
      console.error(err);
      alert('データの登録に失敗しました');
      if (callbackError) callbackError();
    });
}

/**
 * 請求書PDFダウンロード
 * @param i_id
 * @returns
 */
export function getInvoice(
  id: number,
  callbackSuccess: (data: tInvoice) => void,
  callbackError: (() => void) | undefined = undefined
) {
  const ins = create();
  return ins
    .get(`${API_URL.base}/invoice/show/${id}`)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('getInvoice', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの取得に失敗しました');
    });
}
//get-details-info

/**
 * 明細に関する情報取得 主にサマリした金額など
 * @param id
 * @param callbackSuccess
 * @param callbackError
 * @returns
 */
export function postDetailsInfo(
  filter: any,
  callbackSuccess?: (data: tDetailsInfo) => void,
  callbackError?: () => void
) {
  const ins = create();
  return ins
    .post(API_URL.base + '/invoice/get-details-info', {
      filter: filter,
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('postDetailsInfo', res.data);
      if (callbackSuccess) callbackSuccess(res.data);
    })
    .catch((err) => {
      console.error(err);
      alert('データの取得に失敗しました');
      if (callbackError) callbackError();
    });
}

/**
 * 請求書PDFダウンロード
 * @param i_id
 * @returns
 */
export function getInvocies(page: number, filter: any = {}, order: any = {}) {
  const ins = create();
  return ins.get(`${API_URL.base}/invoice/index`, {
    params: { page: page, filter: filter, order: order },
  });
}

/**
 * 個別請求会一覧
 * @returns
 */
export function getInvoiceDetailList({
  filter,
  order = [], // ここでデフォルト値を設定
  withs = [],
  callbackSuccess = undefined,
  callbackError = undefined,
}: apiListProps & {
  callbackSuccess: (({ data, filter }: apiGetListSuccess) => void) | undefined;
}) {
  if (order.length === 0) {
    order.push({ field: 'date', direction: 'asc' });
  }

  const ins = create();
  return ins
    .get(API_URL.base + '/invoice-custom/list', {
      params: { filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('getInvoiceDetailList', res.data);
      if (callbackSuccess) {
        callbackSuccess({ data: res.data, filter: filter });
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの取得に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}

/**
 * 個別請求会社別一覧
 * @returns
 */
export function getInvoiceDetailGroupCId(
  page: number,
  filter: any = {},
  order: tOrder[] = [],
  withs: any = [],
  callbackSuccess: (
    data: tCustomInvoiceGroupCId[],
    currentPage: number,
    lastPage: number,
    filter: any
  ) => void,
  callbackError: (() => void) | undefined = undefined
) {
  if (order.length === 0) {
    order = [{ field: 'c_id', direction: 'asc' }];
  }
  const ins = create();
  return ins
    .get(API_URL.base + '/invoice-custom/index/group-cid', {
      params: { page: page, filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('getInvoiceDetailGroupCId', res.data);
      callbackSuccess(
        res.data.data,
        res.data.current_page,
        res.data.last_page,
        filter
      );
    })
    .catch((err) => {
      console.error(err);
      alert('データの取得に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}

/**
 * 個別請求会社別一覧
 * @returns
 */
export function getInvoiceDetail({
  id,
  withs = [],
  callbackSuccess = undefined,
  callbackError = undefined,
}: apiGetShowProps & {
  callbackSuccess: ((data: tInvoiceDetail) => void) | undefined;
}) {
  const ins = create();
  return ins
    .get(`${API_URL.base}/invoice-custom/show/${id}`, {
      params: { withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('getInvoiceDetail', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの取得に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}

/**
 * 請求書取消し処理
 * @param param0
 * @returns
 */
export function deleteInvoice({
  id,
  callbackSuccess = undefined,
  callbackError = undefined,
}: apiDeleteProps) {
  const ins = create();
  return ins
    .delete(`${API_URL.base}/invoice/destroy/${id}`)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの削除に失敗しました');
      }
      log.debug('deleteInvoice', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの削除に失敗しました');
      if (callbackError) {
        callbackError(err);
      }
    });
}
