import { DeleteButton, PrimaryButton } from 'atoms/Button';
import * as ModalPerson from 'components/company/ModalPerson';
import SearchModal from 'components/company/Search';
import ErrModal from 'components/error/Modal';
import * as apiMst from 'functions/api/mst';
import { changeToState } from 'functions/error/';
import log from 'functions/logger';
import React, { useState } from 'react';
import { tCompany, tCompanySearch, tTradeType } from 'types/company';
import { apiGetIndexSuccess, tErrMsg, tOrder } from 'types/index';
import { initialPerson, tPerson } from 'types/mst';

interface SearchBtnProps {
  setData: React.Dispatch<React.SetStateAction<tCompany[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<React.SetStateAction<tCompanySearch>>;
  setOrders: React.Dispatch<React.SetStateAction<tOrder[]>>;
}
export const SearchBtn = ({
  setData,
  setCurrentPage,
  setLastPage,
  setFilter,
  setOrders,
}: SearchBtnProps) => {
  const [open, setOpen] = useState(false);
  const handleClickSearch = () => {
    log.debug('handleClickSearch');
    setOpen(true);
  };

  const callbackGetCompanies = ({
    data,
    currentPage,
    lastPage,
    filter,
    orders,
  }: apiGetIndexSuccess) => {
    console.log('callbackGetCompanies', orders);
    setData(data);
    setCurrentPage(currentPage);
    setLastPage(lastPage);
    setFilter(filter);
    setOrders(orders || []);
  };

  const callbackSearch = (filter: tCompanySearch, orders: tOrder[]) => {
    apiMst.getCompanies({
      page: 1,
      filter: filter,
      order: orders,
      callbackSuccess: callbackGetCompanies,
    });
  };

  return (
    <>
      <SearchModal
        open={open}
        setOpen={setOpen}
        callback={(data, orders) => callbackSearch(data, orders)}
      />
      <PrimaryButton label="検索" onClick={handleClickSearch} />
    </>
  );
};

interface StoreProps {
  company: tCompany;
  tradeTypeList?: tTradeType[];
  cbs?: (data: tCompany) => void;
  cbe?: (error: any) => void;
}
export const Store = ({ company, cbs, cbe }: StoreProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    apiMst
      .storeCompany(company)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="登録" onClick={handleClick} />
    </>
  );
};

export const Update = ({
  company,
  tradeTypeList = [],
  cbs,
  cbe,
}: StoreProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    apiMst
      .updateCompany(company, tradeTypeList)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="更新" onClick={handleClick} />
    </>
  );
};

interface DeleteProps {
  id: tCompany['id'];
  cbs?: (data: tCompany) => void;
  cbe?: (error: any) => void;
}
export const Delete = ({ id, cbs, cbe }: DeleteProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    if (!id) return;

    apiMst
      .deleteCompany(id)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ削除に失敗しました');
        alert('データを削除しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <DeleteButton label="削除" onClick={handleClick} />
    </>
  );
};

interface PersonEditProps {
  label?: string;
  customPerson?: tPerson;
  cbs?: (data: tPerson) => void;
  cbe?: (error: any) => void;
}
export const PersonEdit = ({
  label = '新規',
  customPerson = initialPerson,
  cbs,
  cbe,
}: PersonEditProps) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    if (cbs) cbs(customPerson);
  };

  const handleClick = () => {
    setShowModal(true);
    if (cbe) cbe(customPerson);
  };

  return (
    <>
      <ModalPerson.Main
        flgOpen={showModal}
        cbOnClose={closeModal}
        customPerson={customPerson}
      />
      <PrimaryButton label={label || '人物'} onClick={handleClick} />
    </>
  );
};
