import { tProjectPriceDetail } from 'types/project';

export const initProjectPriceDetail: tProjectPriceDetail = {
  id: 0,
  pj_id: 0,
  kbn: 0,
  name: '',
  price_unit: undefined,
  quantity: undefined,
  quantity_unit: '',
  price: undefined,
  tax_id: undefined,
  price_tax: undefined,
  memo: '',
};
