import { DeleteButton } from 'atoms/Button';
import ErrModal from 'components/error/Modal';
import { deleteCompanyTradeType } from 'functions/api/mst';
import { changeToState } from 'functions/error/';
import { useState } from 'react';
import { tErrMsg } from 'types';

export const Delete = ({ id }: { id: number }) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const cbSuccess = () => {
    alert('削除しました');
  };

  const cbError = (err: any) => {
    setShowModal(true);
    setValidationMsg(changeToState(err));
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <DeleteButton
        label="削除"
        onClick={() =>
          deleteCompanyTradeType({
            id: id,
            callbackSuccess: cbSuccess,
            callbackError: cbError,
          })
        }
      />
    </>
  );
};
