import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { SmallSelect } from 'atoms/Select';
import * as NumberItem from 'components/input/Number';
import * as SelectItem from 'components/input/Select';
import * as TextItem from 'components/input/Text';
import { InvoiceSheetList } from 'const/comapny/index';
import log from 'functions/logger';
import { tTradeType } from 'types/company';
import { tObjectListReturn } from 'types/useReducer';
import * as TradeTypeBtn from './ButtonTradeType';

interface MainProps {
  object: tObjectListReturn<tTradeType>;
}
const Main = ({ object }: MainProps) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          gap: theme.spacing(2),
        }}
      >
        <Typography variant="h6">取引区分リスト</Typography>
      </Box>
      <TableContainer component={Paper} sx={{ maxWidth: '680px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>名称</TableCell>
              <TableCell>月額</TableCell>
              <TableCell>税率</TableCell>
              <TableCell>明細タイプ</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {object.list.map((rowData: tTradeType, index: number) => (
              <DataRow
                key={`company-trade-types-${index}`}
                index={index}
                object={object}
              />
            ))}
            {/*
            object.list.length < 5 &&
              Array.from({ length: 5 - object.list.length }).map((_, index) => (
                <DataRow
                  key={index}
                  index={index + object.list.length}
                  object={object}
                />
              ))
                */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Main;

const DataRow = ({ index, object }: any) => {
  const selectTradeType = InvoiceSheetList.find(
    (item) => object.list[index] && item.label === object.list[index].sheet_name
  );

  log.debug('DataRow', object.list[index]);

  return (
    <TableRow key={`companyEdit-tradeType-${index}`}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <TextItem.Main
          label=""
          name=""
          value={object.list[index]?.name}
          cbValueChange={(val: string) => {
            object.updateItem(index, { name: val });
          }}
        />
      </TableCell>
      <TableCell>
        <NumberItem.Main
          label=""
          name=""
          value={object.list[index]?.price_month}
          cbValueChange={(val) => {
            object.updateItem(index, { price_month: val || null });
          }}
        />
      </TableCell>
      <TableCell>
        <SelectItem.Tax
          label=""
          value={object.list[index].tax_id || ''}
          flgNoSelect={true}
          cbValueChange={(val) => {
            object.updateItem(index, { tax_id: val || null });
          }}
        />
      </TableCell>
      <TableCell>
        <SmallSelect
          label=""
          items={InvoiceSheetList}
          value={selectTradeType?.id || 0}
          flgNoSelect={true}
          cbValueChange={(val) => {
            // 選択アイテムを取得
            const item = InvoiceSheetList.find((item) => item.id === val);

            if (item === undefined) {
              object.updateItem(index, { sheet_name: '' });
            } else {
              object.updateItem(index, { sheet_name: item?.label });
            }
          }}
        />
      </TableCell>
      <TableCell>
        {object.list[index]?.id && (
          <TradeTypeBtn.Delete id={object.list[index].id} />
        )}
      </TableCell>
    </TableRow>
  );
};
