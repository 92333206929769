import { Box, Grid } from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import { SecondButton } from 'atoms/Button';
import * as DataInput from 'atoms/DateInput';
import { GridItem } from 'atoms/Grid';
import Modal from 'atoms/Modal';
import * as ciBtn from 'components/customInvocie/Button';
import * as NumberItems from 'components/input/Number';
import * as TextItems from 'components/input/Text';
import PriceDetails from 'components/projectPriceDetail/Detail';
import { initCustomInvoiceEdit } from 'const/invoice';
import { MstDataContext } from 'contexts/Mst';
import { initPriceDetails } from 'functions/project/index';
import { useKeyedObject, useObjectList } from 'functions/useReducer/customHook';
import React, { useContext, useEffect, useState } from 'react';
import { tCompany } from 'types/company';
import { tCustomInvoiceEdit } from 'types/invoice';
import { tProjectPriceDetail } from 'types/project';
import { tKeyedObjectReturn, tObjectListReturn } from 'types/useReducer';

interface Props {
  cId: number;
  flgOpen: boolean;
  cbOnClose: () => void;
}
const Main = React.memo(({ cId, flgOpen, cbOnClose }: Props) => {
  const { SYSTEM, companies } = useContext(MstDataContext);
  const [company, setCompany] = useState<tCompany>();

  // 項目
  const edit = useKeyedObject<tCustomInvoiceEdit>({
    ...initCustomInvoiceEdit,
    c_id: cId,
  });

  // 金額明細
  const initCustomPriceDetails = initPriceDetails(SYSTEM);
  const pd = useObjectList<tProjectPriceDetail>(initCustomPriceDetails);

  useEffect(() => {
    if (flgOpen) {
      edit.setList({
        ...initCustomInvoiceEdit,
        c_id: cId,
      });
      pd.setList(initCustomPriceDetails);
    }
  }, [flgOpen]);

  /**
   * 会社マスタ情報
   */
  useEffect(() => {
    if (companies) {
      // companiesからcIdに一致する会社情報を取得
      const company = companies.find((v) => v.id === cId);
      setCompany(company);
    }
  }, [companies, cId]);

  /**
   * 金額明細の変更をeditに反映
   */
  useEffect(() => {
    // pd.listの配列からkbnが明細のものを抽出し、合計金額を算出
    const details = pd.list.find(
      (v) => v.kbn === SYSTEM.project.price_detail.detail
    );
    const separate = pd.list.find(
      (v) => v.kbn === SYSTEM.project.price_detail.separate
    );
    const special = pd.list.find(
      (v) => v.kbn === SYSTEM.project.price_detail.other
    );

    edit.updateItems({
      price_unit: details?.price_unit || undefined,
      quantity: details?.quantity || undefined,
      price: details?.price || undefined,
      price_tax_id: details?.tax_id || undefined,
      price_separate: separate?.price || undefined,
      price_separate_tax_id: separate?.tax_id || undefined,
      price_other: special?.price || undefined,
      price_other_tax_id: special?.tax_id || undefined,
    });
  }, [pd.list]);

  return (
    <Modal
      title={`個別請求明細登録：${company?.name}(${company?.id})`}
      actions={<Actions values={edit} cbs={cbOnClose} />}
      open={flgOpen}
      onClose={cbOnClose}
    >
      <Detail values={edit} pd={pd} />
    </Modal>
  );
});

export default Main;

interface ActionsProps {
  values: tKeyedObjectReturn<tCustomInvoiceEdit>;
  cbs?: () => void;
  cbe?: (error: any) => void;
}
const Actions = (props: ActionsProps) => {
  return (
    <>
      <ciBtn.Insert
        callbackSuccess={(data) => {
          alert('登録しました');
        }}
        data={props.values.list}
      />
      <SecondButton label="閉じる" onClick={props.cbs} />
    </>
  );
};

interface DetailProps {
  values: tKeyedObjectReturn<tCustomInvoiceEdit>;
  pd: tObjectListReturn<tProjectPriceDetail>;
}
const Detail = ({ values, pd }: DetailProps) => {
  return (
    <FlexColumnBox gapSize={2}>
      <Grid container spacing={2}>
        <InvocieDate values={values} />
        <PName values={values} />
        <VehicleName values={values} />
        <TrailerName values={values} />
        <LoadName values={values} />
        <UnloadName values={values} />
        <LuggageName values={values} />
        <Weight values={values} />
        <Memo values={values} />
      </Grid>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={9} xl={7}>
            <PriceDetails values={pd} />
          </Grid>
        </Grid>
      </Box>
    </FlexColumnBox>
  );
};

interface ItemProps {
  values: tKeyedObjectReturn<tCustomInvoiceEdit>;
}
const InvocieDate = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <DataInput.DateInput
      label="日付"
      name="date"
      value={values.list.date}
      onDateChange={(val: string) => values.updateItem('date', val)}
    />
  </GridItem>
);

const PName = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <TextItems.Main
      label="担当"
      name="p_name"
      value={values.list.p_name}
      cbValueChange={(val: string) => values.updateItem('p_name', val)}
    />
  </GridItem>
);

const VehicleName = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <TextItems.Main
      label="車番"
      name="vehicle_name"
      value={values.list.vehicle_name}
      cbValueChange={(val: string) => values.updateItem('vehicle_name', val)}
    />
  </GridItem>
);

const TrailerName = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <TextItems.Main
      label="シャーシ"
      name="trailer_name"
      value={values.list.trailer_name}
      cbValueChange={(val: string) => values.updateItem('trailer_name', val)}
    />
  </GridItem>
);

const LoadName = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <TextItems.Main
      label="発地"
      name="load_name"
      value={values.list.load_name}
      cbValueChange={(val: string) => values.updateItem('load_name', val)}
    />
  </GridItem>
);

const UnloadName = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <TextItems.Main
      label="着地"
      name="unload_name"
      value={values.list.unload_name}
      cbValueChange={(val: string) => values.updateItem('unload_name', val)}
    />
  </GridItem>
);

const LuggageName = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <TextItems.Main
      label="品名・コンテナ番号"
      name="luggage_name"
      value={values.list.luggage_name}
      cbValueChange={(val: string) => values.updateItem('luggage_name', val)}
    />
  </GridItem>
);

const Weight = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <NumberItems.Main
      label="輸送量"
      name="weight"
      value={values.list.weight}
      cbValueChange={(val) => values.updateItem('weight', val || undefined)}
    />
  </GridItem>
);

/*
const PriceUnit = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <NumberItems.PriceUnit
      label="単価"
      name="price_unit"
      unit={values.list.price_unit}
      quantity={values.list.quantity}
      price={values.list.price}
      cbValueChange={(u, q, p) =>
        values.updateItems({ price_unit: u, quantity: q, price: p })
      }
    />
  </GridItem>
);

const Quantity = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <NumberItems.Quantity
      label="数量/重量"
      name="quantity"
      unit={values.list.price_unit}
      quantity={values.list.quantity}
      price={values.list.price}
      cbValueChange={(u, q, p) =>
        values.updateItems({ price_unit: u, quantity: q, price: p })
      }
    />
  </GridItem>
);

const Price = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <NumberItems.Price
      label="金額"
      name="price"
      unit={values.list.price_unit}
      quantity={values.list.quantity}
      price={values.list.price}
      cbValueChange={(u, q, p) =>
        values.updateItems({ price_unit: u, quantity: q, price: p })
      }
    />
  </GridItem>
);

const PriceSeparate = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <NumberItems.Main
      label="割増料金"
      name="price_separate"
      value={values.list.price_separate}
      cbValueChange={(val) => values.updateItem('price_separate', val)}
    />
  </GridItem>
);

const PriceSpecial = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <NumberItems.Main
      label="その他"
      name="price_other"
      value={values.list.price_other}
      cbValueChange={(val) => values.updateItem('price_other', val)}
    />
  </GridItem>
);
*/

const Memo = ({ values }: ItemProps) => (
  <GridItem size={{ xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }}>
    <TextItems.Memo
      row={1}
      value={values.list.memo}
      cbValueChange={(val) => values.updateItem('memo', val)}
    />
  </GridItem>
);
