import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import { FlexBox, FlexColumnBox } from 'atoms/Box';
import Loading from 'atoms/Loading';
import * as invoiceBtn from 'components/closing/Button';
import * as ClosingComponent from 'components/closing/Index';
import * as NumberItems from 'components/input/Number';
import * as TextItems from 'components/input/Text';
import PJModal from 'components/project/Modal';
import { initCompany } from 'const/comapny';
import { closingDateTypeUnload } from 'const/comapny/closingDateType';
import { waypointKbnLoad } from 'const/index';
import { defSheetName, initInvoice, initInvoiceDetail } from 'const/invoice';
import { closingStatus } from 'const/project/index';
import { MstDataContext } from 'contexts/Mst';
import ContentFrame from 'frames/ContentsFrame';
import { postDetailsInfo } from 'functions/api/invoice';
import { getCompany, getCompanyPerson } from 'functions/api/mst';
import { getProjectList } from 'functions/api/project';
import { reduceMax, reduceSum, sumRecordValues } from 'functions/array';
import { formatNumber } from 'functions/index';
import {
  formatMonthDay,
  formatYearMonth,
  getSameDayPreviousMonth,
  mathDateTime,
} from 'functions/time';
import { useKeyedObject, useObjectList } from 'functions/useReducer/customHook';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { tAddress } from 'types/address';
import { tCompany } from 'types/company';
import { tDetailsInfo, tInvoice, tInvoiceDetail } from 'types/invoice';
import { initialPerson, tPerson, tVehicle } from 'types/mst';
import { tProject } from 'types/project';
import { tSYSTEM } from 'types/system';
import { tKeyedObjectReturn, tObjectListReturn } from 'types/useReducer';

type tCompanyCustom = tCompany & { address: tAddress };

export default function Main() {
  const { c_id, p_id } = useParams();
  const { loading, selfInfomation, SYSTEM, vehicles, trailer } =
    useContext(MstDataContext);
  const [trigger, setTrigger] = useState<string>(new Date().toLocaleString());
  const [flgLoading, setFlgLoading] = useState<boolean>(false);

  /**
   * パラメータから取得する会社情報
   */
  const company = useKeyedObject<tCompany>(initCompany);

  /**
   * パラメータに担当者が指定されていた時に取得する担当者情報
   */
  const person = useKeyedObject<tPerson>(initialPerson);

  /**
   * 請求書データ
   * 合計金額、消費税、請求金額を格納する
   * 調整金等もここ
   */
  const iv = useKeyedObject<tInvoice>(initInvoice);

  /**
   * 請求書明細部分
   * 案件情報を取得してこの中に格納する
   * これをPOSTして請求書データを作成する
   */
  const ivd = useObjectList<tInvoiceDetail>([]);

  /**
   * 明細の追加情報 主にサマリー情報
   */
  const detailsInfo = useKeyedObject<tDetailsInfo>({
    price: 0,
    pricesForCtt: {},
    pricesForTax: {},
    taxes: {},
    detailCountForCtt: {},
  });

  const [selectedPjId, setSelectedPjId] = useState<tProject['id']>(undefined);

  const closingStatusIds = closingStatus.map((status) => status.id);

  /**
   * 会社マスタ情報の取得
   * @returns
   */
  const getCompanyInfo = async () => {
    if (SYSTEM === null || vehicles === null || trailer === null) return;

    if (p_id && p_id !== 'none') {
      await getCompanyPerson(Number(p_id)).then((res: { data: tPerson }) => {
        person.setList(res.data);
      });
    } else {
      person.clearList();
    }

    await getCompany(Number(c_id))
      .then((res: { data: tCompanyCustom }) => {
        const compData = res.data;

        company.setList(compData);

        const closingDate = getSameDayPreviousMonth(compData.closing_date);
        // 締め日の一月前の翌日を取得
        const closingDateFrom = mathDateTime(
          getSameDayPreviousMonth(compData.closing_date, closingDate),
          [0, 0, 1, 0, 0, 0]
        );

        iv.updateItems({
          c_id: compData.id,
          c_name: compData.name,
          c_invoice_no: compData.invoice_no,
          c_postal_code: compData.post_number,
          c_address1: compData.address1,
          c_address2: compData.address2,
          title: `${formatYearMonth(closingDate)}分（${formatMonthDay(closingDateFrom)}〜${formatMonthDay(closingDate)})`,
        });
      })
      .catch((err) => {
        console.error(err);
        company.clearList();
        alert('取引先情報の取得に失敗しました');
      });
  };

  /**
   * 案件情報の取得
   * @param filter
   * @returns
   */
  const getPjList = async (filter: any) => {
    if (SYSTEM === null || vehicles === null || trailer === null) return;

    await getProjectList(
      filter,
      [
        {
          field: 'ctt_id',
          direction: 'asc',
        },
        {
          field: 'load_datetime',
          direction: 'asc',
        },
        {
          field: 'unload_datetime',
          direction: 'asc',
        },
      ],
      ['person']
    )
      .then((res: { data: tProject[] }) => {
        // 取得した案件情報を請求書明細に格納
        const pjs = res.data;
        const details: tInvoiceDetail[] = convertPjToIvd(
          SYSTEM,
          vehicles,
          trailer,
          pjs,
          company.list
        );

        ivd.setList(details);
      })
      .catch((err) => {
        console.log(err);
        alert('案件情報の取得に失敗しました');
      });
  };

  /**
   * リスト情報に変更があったタイミングで明細情報を再取得
   */
  useEffect(() => {
    if (!ivd.list || ivd.list.length === 0) {
      detailsInfo.clearList();
      //iv.clearList();
      //ivd.clearList();
      return;
    }

    setFlgLoading(true);

    const cbs = async (data: tDetailsInfo) => {
      detailsInfo.setList(data);

      const tax = sumRecordValues(data.taxes);
      const detailCount = sumRecordValues(data.detailCountForCtt);

      iv.updateItems({
        price: data.price,
        tax: tax,
        tax_exempt: data.pricesForTax[SYSTEM.tax.exempt.id] || 0,
        amount: data.price + tax + (iv.list.tax_adjust || 0),
        detail_count: detailCount,
      });
    };

    const cbe = () => {
      alert('明細情報の取得に失敗しました');
      setFlgLoading(false);
    };

    // 必要なパラメータのみに制限する
    const pjs = ivd.list
      .filter((detail) => detail.flg === true)
      .map((detail) => detail.pj_id);

    postDetailsInfo({ c_id: c_id, pjs: pjs }, cbs, cbe);

    setFlgLoading(false);
  }, [ivd.list]);

  useEffect(() => {
    setFlgLoading(true);
    getCompanyInfo();
    setFlgLoading(false);
  }, [c_id, p_id]);

  useEffect(() => {
    setFlgLoading(true);

    let filter: { c_id: number; status: number | number[]; p_id?: number } = {
      c_id: Number(c_id),
      status: closingStatusIds,
    };

    if (p_id && p_id !== 'none') {
      // filterに担当者IDを追加
      filter = {
        ...filter,
        p_id: Number(p_id),
      };
    }

    getPjList(filter);

    setFlgLoading(false);
  }, [trigger]);

  return (
    <>
      <Loading flg={loading || !selfInfomation || !company || flgLoading} />
      <PJModal
        open={selectedPjId ? true : false}
        onClose={() => {
          setSelectedPjId(undefined);
        }}
        pjId={selectedPjId}
        callbackNomal={() => {
          setSelectedPjId(undefined);
          getCompanyInfo();
        }}
      />
      <ContentFrame
        HeadContent={<HeadContent iv={iv} ivd={ivd} />}
        MainContent={
          <MainContent ivd={ivd} setSelectedPjId={setSelectedPjId} />
        }
        SubContent={<></>}
      ></ContentFrame>
    </>
  );
}

interface HeadContentProps {
  iv: tKeyedObjectReturn<tInvoice>;
  ivd: tObjectListReturn<tInvoiceDetail>;
}

const HeadContent = ({ iv, ivd }: HeadContentProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const data = iv.list;

  return (
    <FlexColumnBox gapSize={6} width="100%">
      <Box sx={{ display: 'flex', flexFlow: 'row' }}>
        <invoiceBtn.Closing
          iv={iv.list}
          ivd={ivd.list}
          callbackPDFClose={() => {
            navigate('/paperwork/closing');
          }}
        />
      </Box>
      <FlexBox width="100%">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{`${data.c_name}(${data.c_id})`}</Typography>
          </Grid>
          <ClosingComponent.CompanyPostalCode
            value={data.c_postal_code}
            cbs={(val: string) => {
              iv.updateItem('c_postal_code', val.toString());
            }}
          />
          <ClosingComponent.CompanyAddress1
            value={data.c_address1}
            cbs={(val) => iv.updateItem('c_address1', val)}
          />
          <ClosingComponent.CompanyAddress2
            value={data.c_address2}
            cbs={(val) => iv.updateItem('c_address2', val)}
          />
          <Grid item xs={12} md={6}>
            <TableContainer
              component={Paper}
              sx={{ maxWidth: theme.breakpoints.values.sm }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>合計</TableCell>
                    <TableCell>消費税額</TableCell>
                    <TableCell>非課税</TableCell>
                    <TableCell>調整金</TableCell>
                    <TableCell>請求金額</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography className="number">
                        {iv.list.price
                          ? formatNumber(String(iv.list.price), false)
                          : '0'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {iv.list.tax
                          ? formatNumber(String(iv.list.tax), false)
                          : '0'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {iv.list.tax_exempt
                          ? formatNumber(String(iv.list.tax_exempt), false)
                          : '0'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <NumberItems.Main
                        value={iv.list.tax_adjust}
                        cbValueChange={(val) =>
                          iv.updateItems({
                            tax_adjust: val || 0,
                            amount: (val || 0) + iv.list.price + iv.list.tax,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {iv.list.amount
                          ? formatNumber(String(iv.list.amount), false)
                          : '0'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FlexBox>
    </FlexColumnBox>
  );
};

const MainContent = ({
  ivd,
  setSelectedPjId,
}: {
  ivd: tObjectListReturn<tInvoiceDetail>;
  setSelectedPjId: React.Dispatch<React.SetStateAction<tProject['id']>>;
}) => {
  if (!ivd.list) {
    return <Typography>案件情報はありません</Typography>;
  }

  return (
    <FlexColumnBox gapSize={6} sx={{ overflow: 'hidden', height: '100%' }}>
      <TableContainer
        component={Paper}
        sx={{
          margin: '0 auto',
          padding: '2px auto',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>取引区分</TableCell>
              <TableCell>対象</TableCell>
              <TableCell>日付</TableCell>
              <TableCell>担当</TableCell>
              <TableCell>車番</TableCell>
              <TableCell>シャーシ</TableCell>
              <TableCell>発地</TableCell>
              <TableCell>着地</TableCell>
              <TableCell>品名</TableCell>
              <TableCell>重量</TableCell>
              <TableCell>数量</TableCell>
              <TableCell>単価</TableCell>
              <TableCell>金額</TableCell>
              <TableCell>割増料金</TableCell>
              <TableCell>その他料金</TableCell>
              <TableCell>備考</TableCell>
              <TableCell>案件管理番号</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(ivd.list as tInvoiceDetail[]).map(
              (detail: tInvoiceDetail, index: number) => {
                // 車番とシャーシにIDがある場合は名称をナンバーのみに変換する

                return (
                  <TableRow
                    key={`project-list-${detail.pj_id}`}
                    onDoubleClick={() =>
                      setSelectedPjId(detail.pj_id || undefined)
                    }
                  >
                    <TableCell>
                      <Typography className="number">{index + 1}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{detail.ctt_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={detail.flg}
                        onChange={() =>
                          ivd.updateItem(index, {
                            flg: !detail.flg,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {new Date(detail.date).toLocaleDateString()}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{detail.p_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{detail.vehicle_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{detail.trailer_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <TextItems.Main
                        value={detail.load_name}
                        cbValueChange={(val) => {
                          ivd.updateItem(index, { load_name: val });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextItems.Main
                        value={detail.unload_name}
                        cbValueChange={(val) => {
                          ivd.updateItem(index, { unload_name: val });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography>{detail.luggage_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {detail.weight && detail.weight > 0
                          ? formatNumber(String(detail.weight), false)
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {detail.quantity && detail.quantity > 0
                          ? formatNumber(String(detail.quantity), true)
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {detail.price_unit && detail.price_unit > 0
                          ? formatNumber(detail.price_unit.toString(), true)
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {detail.price
                          ? formatNumber(String(detail.price), false)
                          : '0'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {detail.price_separate && detail.price_separate > 0
                          ? formatNumber(String(detail.price_separate), false)
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {detail.price_other && detail.price_other > 0
                          ? formatNumber(String(detail.price_other), false)
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextItems.Main
                        value={detail.memo}
                        cbValueChange={(val) => {
                          ivd.updateItem(index, { memo: val });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography>{detail.pj_id}</Typography>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </FlexColumnBox>
  );
};

function convertPjToIvd(
  SYSTEM: tSYSTEM,
  vehicles: tVehicle[],
  trailer: tVehicle[],
  pjs: tProject[],
  company: tCompany
): tInvoiceDetail[] {
  const details: tInvoiceDetail[] = pjs.map((pj: tProject, index: number) => {
    // 初期値
    const data: tInvoiceDetail = { ...initInvoiceDetail, flg: true };

    // 案件情報からの情報をセット
    data.pj_id = pj.id;
    data.ctt_id = pj.ctt_id;

    const tt = company.trade_types?.find((tt) => tt.id === pj.ctt_id);
    if (tt) {
      data.ctt_name = tt.name;
      data.sheet_name = tt.sheet_name;
    } else {
      data.ctt_name = '';
      data.sheet_name = defSheetName;
    }

    if (company.closing_date_type === closingDateTypeUnload.id) {
      data.date = new Date(pj.unload_datetime).toLocaleDateString();
    } else {
      data.date = new Date(pj.load_datetime).toLocaleDateString();
    }
    if (pj.person) {
      data.p_name = pj.person.family_name + pj.person.given_name;
    }

    // 車番、シャーシはIDがある場合はマスタのナンバーのみに変換する
    data.vehicle_name = pj.vehicle_name;
    if (pj.v_id) {
      const vObj = vehicles.find((v: tVehicle) => v.id === pj.v_id);
      if (vObj) {
        data.vehicle_name = vObj.plate_number;
      }
    }

    data.trailer_name = pj.trailer_name;
    if (pj.trailer_v_id) {
      const vtObj = trailer.find((v: tVehicle) => v.id === pj.trailer_v_id);
      if (vtObj) {
        data.trailer_name = vtObj.plate_number;
      }
    }

    // 積卸地は各区分の略称1を/区切りで出力
    data.load_name = pj.waypoints
      .filter((wp) => wp.kbn === waypointKbnLoad.id)
      .map((wp) => wp.abbreviation1)
      .join('/');
    data.unload_name =
      pj.waypoints
        .filter((wp) => wp.kbn !== waypointKbnLoad.id)
        .map((wp) => wp.abbreviation1)
        .join('/') || '';

    data.luggage_name = pj.l_name;
    data.weight = pj.weight;
    data.memo = pj.memo;

    // コンテナコードは輸送情報単位に着くもののため、輸送情報を取得してセットする必要がある
    //data.container_code = pj.container_code;

    // 金額明細
    //console.log('price_details', pj.price_details);
    const priceDetails = pj.price_details;
    data.price_details = priceDetails;

    // 区分別に処理
    const pdDetails = priceDetails?.filter(
      (pd) => pd.kbn === SYSTEM?.project.price_detail.detail
    );
    if (pdDetails) {
      //console.log('pdDetails', pdDetails);
      data.price_unit = reduceSum(pdDetails, 'price_unit');
      data.quantity = reduceMax(pdDetails, 'quantity');
      data.price = reduceSum(pdDetails, 'price');
    }

    // 割増料金
    const pdSeparate = priceDetails?.filter(
      (pd) => pd.kbn === SYSTEM?.project.price_detail.separate
    );
    if (pdSeparate) {
      data.price_separate = reduceSum(pdSeparate, 'price');
    }

    // その他料金
    const pdOther = priceDetails?.filter(
      (pd) => pd.kbn === SYSTEM?.project.price_detail.other
    );
    if (pdOther) {
      data.price_other = reduceSum(pdOther, 'price');
    }

    return data;
  });

  return details || [];
}
