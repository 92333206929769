import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface MainProps {
  HeadContent: React.ReactNode;
  MainContent: React.ReactNode;
  SubContent: React.ReactNode;
}

const Main = ({ HeadContent, MainContent, SubContent }: MainProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '100%', // ビューポート全体を占める
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* 上部コンテンツ */}
      <Box
        sx={{
          //maxHeight: '85%', // 最大85%の高さ
          height: '100%',
          flexGrow: 1, // 可能な範囲で拡大
          overflow: 'auto', // スクロール対応
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          className="HeadContent"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: theme.spacing(2),
            p: theme.spacing(2),
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.palette.background.default, // スクロール時に背景維持
          }}
        >
          {HeadContent}
        </Box>
        <Box
          className="MainContent"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            p: theme.spacing(1),
          }}
        >
          {MainContent}
        </Box>
      </Box>

      {/* 下部コンテンツ */}
      <Box
        className="SubContent"
        sx={{
          backgroundColor: theme.palette.common.white,
          overflow: 'auto',
          p: theme.spacing(1),
        }}
      >
        {SubContent}
      </Box>
    </Box>
  );
};

export default Main;
