import { TableCell, TableRow, Typography } from '@mui/material';
import { NavigateInput } from 'components/customInvocie/Button';
import PageNation from 'components/Pagenation';
import { MstDataContext } from 'contexts/Mst';
import TableFrame from 'frames/TableFrame';
import { getInvoiceDetailGroupCId } from 'functions/api/invoice';
import { formatNumber } from 'functions/index';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { tOrder } from 'types/index';
import { tCustomInvoiceGroupCId, tSearchInvoice } from 'types/invoice';

const Main = () => {
  const { page } = useParams();
  const { SYSTEM } = useContext(MstDataContext);
  const navigate = useNavigate();
  const [datas, setDatas] = useState<tCustomInvoiceGroupCId[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);

  const initSearch = {
    c_id: undefined,
  };
  const [search, setSearch] = useState<tSearchInvoice>(initSearch);
  const [orders, setOrders] = useState<tOrder[]>([]);

  useEffect(() => {
    if (!page) {
      // 空ということは初期可
      setSearch(initSearch);
      setOrders([]);

      // 一ページ目へ遷移
      navigate('/paperwork/custom-invoice/1');
    } else {
      // ページ番号が指定されている場合
      setCurrentPage(Number(page));
    }
  }, [page]);

  useEffect(() => {
    const cbs = (
      data: tCustomInvoiceGroupCId[],
      currentPage: number,
      lastPage: number,
      filter: []
    ) => {
      setDatas(data);
      setCurrentPage(currentPage);
      setLastPage(lastPage);
    };

    const cbe = () => {
      // ページ番号が指定されている場合
      navigate('/paperwork/custom-invoice/1');
    };

    // データ取得処理
    getInvoiceDetailGroupCId(currentPage, search, orders, [], cbs, cbe);
  }, [currentPage]);

  return (
    <TableFrame
      HeadContent={<HeadContent />}
      TableHeaderRows={<TableHeaderRows />}
      TableBodyRows={<TableBodyRows datas={datas} />}
      SubContent={
        <SubContent
          lastPages={lastPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      }
    />
  );
};

export default Main;

const HeadContent = () => {
  return (
    <>
      <NavigateInput />
    </>
  );
};

const TableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>会社名</TableCell>
      <TableCell>明細数</TableCell>
      <TableCell>合計金額</TableCell>
    </TableRow>
  );
};

interface TableBodyRowsProps {
  datas: tCustomInvoiceGroupCId[];
}
const TableBodyRows = ({ datas }: TableBodyRowsProps) => {
  const navigate = useNavigate();
  return (
    <>
      {datas.map((data) => {
        return (
          <TableRow
            key={`custom-invoice-groupby-id-${data.c_id}`}
            onDoubleClick={() =>
              navigate(`/paperwork/custom-invoice/edit/${data.c_id}`)
            }
          >
            <TableCell>{`${data.abbreviation}(${data.c_id})`}</TableCell>
            <TableCell>
              <Typography className="number">
                {formatNumber(data.group_count, false)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="number">
                {formatNumber(data.sum_price, false)}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

interface SubContentProps {
  lastPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}
const SubContent = ({
  lastPages,
  currentPage,
  setCurrentPage,
}: SubContentProps) => {
  const navigate = useNavigate();
  return (
    <PageNation
      totalPages={lastPages}
      currentPage={currentPage}
      handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
        navigate(`/paperwork/custom-invoice/${value}`)
      }
    />
  );
};
