import { API_URL, create } from 'functions/axios';
import log from 'functions/logger';
import { tCustomInvoiceEdit, tDetailsInfo, tInvoice } from 'types/invoice';

/**
 * 個別請求登録
 * @param callbackSuccess
 * @param callbackError
 * @param data
 * @returns
 */
export function storeCustomInvoiceEdit(
  callbackSuccess: ((data: tCustomInvoiceEdit) => void) | undefined,
  callbackError: ((err: any) => void) | undefined,
  data: tCustomInvoiceEdit
) {
  const ins = create();
  return ins
    .post(`${API_URL.base}/invoice-custom/store`, data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの登録に失敗しました');
      }
      log.debug('storeCustomInvoiceEdit', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      //alert('データの登録に失敗しました');
      if (callbackError) {
        callbackError(err);
      }
    });
}

/**
 * 個別請求更新
 * @param callbackSuccess
 * @param callbackError
 * @param data
 * @returns
 */
export function updateCustomInvoiceEdit(
  callbackSuccess: ((data: tCustomInvoiceEdit) => void) | undefined,
  callbackError: (() => void) | undefined,
  data: tCustomInvoiceEdit
) {
  const ins = create();
  return ins
    .post(`${API_URL.base}/invoice-custom/update/${data.id}`, data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの更新に失敗しました');
      }
      log.debug('updateCustomInvoiceEdit', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの更新に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}

export function deleteCustomInvoiceEdit(
  callbackSuccess: ((data: tCustomInvoiceEdit) => void) | undefined,
  callbackError: ((err: any) => void) | undefined,
  id: number
) {
  const ins = create();
  return ins
    .post(`${API_URL.base}/invoice-custom/destroy/${id}`)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの削除に失敗しました');
      }
      log.debug('deleteCustomInvoiceEdit', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの削除に失敗しました');
      if (callbackError) {
        callbackError(err);
      }
    });
}

export function postDetailsInfo(
  filter: {
    c_id: number;
    ids: tCustomInvoiceEdit['id'][];
  },
  callbackSuccess: ((data: tDetailsInfo) => void) | undefined,
  callbackError: ((err: any) => void) | undefined
) {
  const ins = create();
  return ins
    .post(`${API_URL.base}/invoice-custom/get-details-info`, { filter: filter })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('請求書の作成に失敗しました');
      }
      log.debug('getPrices', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError(err);
      }
    });
}

export function makeInvoice(
  filter: {
    invoice: tInvoice;
    ids: tCustomInvoiceEdit['id'][];
  },
  callbackSuccess: ((data: tInvoice) => void) | undefined,
  callbackError: ((err: any) => void) | undefined
) {
  const ins = create();
  return ins
    .post(`${API_URL.base}/invoice-custom/make-invoice`, filter)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('請求書の作成に失敗しました');
      }
      log.debug('makeInvoice', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      //alert('請求書の作成に失敗しました');
      if (callbackError) {
        callbackError(err);
      }
    });
}
