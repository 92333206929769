const SYSTEM = {
  domain: 'system',
  tranMethod: {
    own: {
      id: 1,
      label: '自社',
    },
    charter: {
      id: 2,
      label: '傭車',
    },
  },
  tax: {
    default: {
      id: 10,
      tax: 0.1,
      label: '10%',
    },
    exempt: {
      id: 90,
      tax: 0,
      label: '非課税',
      flg: false,
    },
    10: {
      id: 10,
      tax: 0.1,
      label: '10%',
      flg: true,
    },
    8: {
      id: 8,
      tax: 0.08,
      label: '8%',
      flg: false,
    },
    list: [
      {
        id: 10,
        tax: 0.1,
        label: '10%',
        flg: true,
      },
      {
        id: 8,
        tax: 0.08,
        label: '8%',
        flg: false,
      },
      {
        id: 90,
        tax: 0,
        label: '非課税',
        flg: true,
      },
    ],
  },
  project: {
    waypoints_kbn: {
      list: {
        '1': '積地',
        '2': '卸地',
      },
      load: '1',
      unload: '2',
    },
    warning_level: [
      'none',
      '#FFFFE0',
      '#FFF8DC',
      '#FAFAD2',
      '#FFEFD5',
      '#FFDAB9',
      '#FFA07A',
      '#FF7F50',
      '#FF6347',
      '#FF4500',
    ],
    status: {
      list: [
        {
          id: 0,
          label: '見積中',
        },
        {
          id: 10,
          label: '受注済',
        },
        {
          id: 20,
          label: '運行指示作成済',
        },
        {
          id: 30,
          label: '運送済み',
        },
        {
          id: 40,
          label: '未処理',
        },
        {
          id: 41,
          label: '差戻し',
        },
        {
          id: 49,
          label: 'チェック済み',
        },
        {
          id: 50,
          label: '締め処理済み',
        },
        {
          id: 59,
          label: '請求済み',
        },
        {
          id: 90,
          label: '完了系',
        },
        {
          id: 99,
          label: '失注',
        },
      ],
      quotation: 0,
      ordered: 10,
      instructCreated: 20,
      delivered: 30,
      pending: 40,
      remant: 41,
      checked: 49,
      closed: 50,
      charged: 59,
      completed: 90,
      lost: 99,
      view: {
        tranSchedule: [0, 10, 20, 30, 40, 41, 49, 50, 59, 90, 99],
      },
    },
    price_detail: {
      list: [
        {
          id: 1,
          label: '内訳',
        },
        {
          id: 2,
          label: '別途請求',
        },
        {
          id: 3,
          label: '経費',
        },
        {
          id: 9,
          label: 'その他',
        },
      ],
      detail: 1,
      separate: 2,
      expenses: 3,
      other: 9,
    },
  },
  transport: {
    status: {
      unassigned: {
        id: 0,
        label: '運行指示未割当',
      },
      not_shipped: {
        id: 1,
        label: '未運送',
      },
      in_transit: {
        id: 2,
        label: '運送中',
      },
      delivered: {
        id: 3,
        label: '運送済み',
      },
      canceled: {
        id: 9,
        label: 'キャンセル',
      },
    },
    valid_status: [0, 1, 2, 3],
  },
  instruction: {
    status: {
      list: [
        {
          id: 0,
          label: '未指示',
        },
        {
          id: 10,
          label: '指示済み',
        },
        {
          id: 30,
          label: '輸送中',
        },
        {
          id: 90,
          label: '完了',
        },
        {
          id: 99,
          label: '中止',
        },
      ],
      NotContacted: 0,
      AlreadyContacted: 10,
      InTransit: 30,
      Instructed: 90,
      Suspend: 99,
    },
  },
  invoice: {
    kbn: {
      list: [
        {
          id: 1,
          label: '案件',
        },
        {
          id: 2,
          label: '個別入力',
        },
      ],
      project: 1,
      custom: 2,
    },
    custom: {
      status: {
        edit: {
          id: 0,
          label: '編集中',
        },
        invoiced: {
          id: 9,
          label: '請求済',
        },
      },
    },
  },
};

export default SYSTEM;
