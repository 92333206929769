import { NumberField } from 'atoms/TextField';
import * as calcPrice from 'functions/price';
import { ItemNumberFieldProps } from 'types';

export const Main = ({
  label = '',
  name = '',
  value,
  cbValueChange,
  flgInnerLabel = false,
}: ItemNumberFieldProps) => {
  return (
    <NumberField
      label={`${label}`}
      name={name}
      value={value}
      cbHandleChangeValues={cbValueChange}
      flgFloat={false}
      innerLabel={flgInnerLabel}
    />
  );
};

interface CalcPriceProps {
  label: string;
  name: string;
  unit?: number | undefined | null;
  quantity?: number | undefined | null;
  price?: number | undefined | null;
  cbValueChange?: (
    unit: number | undefined | null,
    quantity: number | undefined | null,
    price: number | undefined | null
  ) => void;
  flgFloat?: boolean;
  flgInnerLabel?: boolean;
}

export const PriceUnit = ({
  label = '単価',
  name = 'price_unit',
  unit,
  quantity,
  price,
  cbValueChange,
  flgFloat = true,
  flgInnerLabel = false,
}: CalcPriceProps) => {
  const handleChange = (val: number | null) => {
    // 単価、数量、金額を取得
    const [u, q, p] = calcPrice.calcPriceFromUnit(val, quantity, price);

    if (cbValueChange) {
      cbValueChange(u, q, p);
    }
  };

  return (
    <NumberField
      label={label}
      name={name}
      value={unit}
      cbHandleChangeValues={handleChange}
      flgFloat={flgFloat}
      innerLabel={flgInnerLabel}
    />
  );
};

export const Quantity = ({
  label = '数量/重量',
  name = 'quantity',
  unit,
  quantity,
  price,
  cbValueChange,
  flgFloat = false,
  flgInnerLabel = false,
}: CalcPriceProps) => {
  const handleChange = (val: number | null) => {
    // 単価、数量、金額を取得
    const [u, q, p] = calcPrice.calcPriceFromQuantity(val, unit, price);

    if (cbValueChange) {
      cbValueChange(u, q, p);
    }
  };

  return (
    <NumberField
      label={label}
      name={name}
      value={quantity}
      cbHandleChangeValues={handleChange}
      flgFloat={flgFloat}
      innerLabel={flgInnerLabel}
    />
  );
};

export const Price = ({
  label = '金額',
  name = 'price',
  unit,
  quantity,
  price,
  cbValueChange,
  flgFloat = false,
  flgInnerLabel = false,
}: CalcPriceProps) => {
  const handleChange = (val: number | null) => {
    // 単価、数量、金額を取得
    const [u, q, p] = calcPrice.calcPriceFromPrice(val, unit, quantity);

    if (cbValueChange) {
      cbValueChange(u, q, p);
    }
  };

  // 金額は小数点を許可しない
  price = price ? Math.floor(price) : null;

  return (
    <NumberField
      label={label}
      name={name}
      value={price}
      cbHandleChangeValues={handleChange}
      flgFloat={flgFloat}
      innerLabel={flgInnerLabel}
    />
  );
};
