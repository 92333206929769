import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/system';
import { FlexColumnBox } from 'atoms/Box';
import { PrimaryButton, SecondButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import * as InstButton from 'components/instruction/Button';
import * as PJButton from 'components/project/Button';
import PJDetail from 'components/project/Detail';
import PriceDetails from 'components/projectPriceDetail/Detail';
import * as ComponentTransport from 'components/transport/Index';
import initTransport from 'const/transport/index';
import { MstDataContext } from 'contexts/Mst';
import { getProject } from 'functions/api/project';
import * as ApiTran from 'functions/api/transport';
import { handleChangeValues } from 'functions/handles';
import { initPriceDetails } from 'functions/project/index';
import { useObjectList } from 'functions/useReducer/customHook';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeProviderCustom } from 'themes/Base';
import {
  initProject,
  initWaypoints,
  tProject,
  tProjectPriceDetail,
  tWaypoints,
} from 'types/project';
import { tTransport } from 'types/transport';
import { WaypointsTable } from './Waypoint';

interface MainProps {
  open: boolean;
  onClose: () => void;
  pjId?: number;
  initCustom?: tProject;
  callbackNomal: () => void;
  callbackError?: (() => void) | undefined;
  tranNo?: number;
}

export default function Main({
  open,
  onClose,
  pjId,
  initCustom = initProject,
  callbackNomal,
  callbackError = undefined,
  tranNo = undefined,
}: MainProps) {
  const { SYSTEM } = useContext(MstDataContext);
  const theme = useTheme();
  const [project, setProject] = useState<tProject>(initCustom);
  const [waypoints, setWaypoints] = useState<tWaypoints>(initWaypoints);

  const [transport, setTransport] = useState<tTransport>();

  const initCustomPriceDetails = initPriceDetails(SYSTEM);
  const pd = useObjectList<tProjectPriceDetail>(initCustomPriceDetails);

  const callbackCloseClick = () => {
    onClose();
    callbackNomal();
  };

  const callbackUpdateTran = (data: tTransport) => {
    setTransport(data);
  };

  useEffect(() => {
    if (pjId) {
      const cbsGetProject = (data: tProject) => {
        setProject(data);
        setWaypoints(data.waypoints);
        pd.setList(
          data.price_details && data.price_details.length > 0
            ? data.price_details
            : initCustomPriceDetails
        );
      };

      const cbeGetproject = (error: any) => {
        //log.debug('project/Modal.tsx getProject Error', error);
        alert('案件情報の取得に失敗しました。');
      };

      getProject(pjId, cbsGetProject, cbeGetproject);
    } else {
      setProject(initCustom);
      setWaypoints(initCustom.waypoints);
      pd.setList(initCustomPriceDetails);
    }
  }, [pjId, initCustom]);

  useEffect(() => {
    if (pjId && tranNo) {
      const cbsGetTran = (data: tTransport) => {
        setTransport(data);
      };

      const cbeGetTran = (error: any) => {
        alert('輸送情報の取得に失敗しました。');
        setTransport(initTransport);
      };

      // トランスポート情報取得
      ApiTran.getTransportByParams(pjId, tranNo, cbsGetTran, cbeGetTran);
    } else {
      setTransport(undefined);
    }
  }, [pjId, tranNo]);

  const ActionBox = styled(Box)({
    display: 'flex',
    gap: theme.spacing(2),
  });

  useEffect(() => {
    if (!open) {
      setProject(initCustom);
      setWaypoints(initCustom.waypoints);
      setTransport(undefined);
      pd.setList(initCustomPriceDetails);
    }
  }, [open]);
  return (
    <ThemeProviderCustom>
      <Modal
        title={`案件情報（${pjId ? pjId : '新規'}）${transport ? `輸送情報(${transport.id})` : ''}`}
        open={open}
        onClose={onClose}
        actions={
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <ActionBox sx={{ justifyContent: 'flex-start' }}>
                {pjId && (
                  <PJButton.Delete
                    pjId={project.id}
                    callbackNomal={callbackCloseClick}
                    callbackError={callbackError}
                  />
                )}
              </ActionBox>
            </Grid>
            <Grid item xs={4}>
              <ActionBox sx={{ justifyContent: 'center' }}>
                {pjId ? (
                  <>
                    <PJButton.Update
                      projectInfo={project}
                      waypoints={waypoints}
                      priceDetails={pd.list}
                      callbackNomal={callbackNomal}
                      callbackError={callbackError}
                    />
                    <InstButton.ModalInstruction pjId={pjId} />
                  </>
                ) : (
                  <>
                    <PJButton.Insert
                      label={'登録'}
                      projectInfo={project}
                      waypoints={waypoints}
                      priceDetails={pd.list}
                      callbackNomal={callbackNomal}
                      callbackError={callbackError}
                    />
                    <PJButton.Insert
                      label={'続けて登録'}
                      projectInfo={project}
                      waypoints={waypoints}
                      callbackError={callbackError}
                    />
                  </>
                )}
              </ActionBox>
            </Grid>
            <Grid item xs={4}>
              <ActionBox sx={{ justifyContent: 'flex-end' }}>
                <SecondButton onClick={onClose} label={'閉じる'} />
              </ActionBox>
            </Grid>
          </Grid>
        }
      >
        <FlexColumnBox gapSize={2}>
          {transport?.id && (
            <Grid container spacing={2}>
              <ComponentTransport.ContainerCode
                label="コンテナ番号"
                name="container_code"
                value={transport.container_code}
                cbValueChange={(value: string) => {
                  handleChangeValues(value, 'container_code', setTransport);
                }}
              />
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <PrimaryButton
                  label="登録"
                  onClick={() => {
                    ApiTran.update({
                      id: transport.id,
                      data: transport,
                      callbackSuccess: callbackUpdateTran,
                    });
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              p: 2,
              border: '1px solid',
              borderColor: 'grey.500',
              borderRadius: 1,
            }}
          >
            <PJDetail pj={project} setPj={setProject}>
              {/* 項目とメモの間に挿入 */}
              <Grid item xs={12}>
                <WaypointsTable
                  label="積地・卸地"
                  values={waypoints}
                  setValues={setWaypoints}
                />
              </Grid>
              <Grid item xs={12} md={9} lg={8} xl={7}>
                <PriceDetails values={pd} />
              </Grid>
              <Grid item xs={0} md={3}></Grid>
            </PJDetail>
          </Box>
        </FlexColumnBox>
      </Modal>
    </ThemeProviderCustom>
  );
}
