import { Typography } from '@mui/material';
import Loading from 'atoms/Loading';
import * as SelectItems from 'components/input/Select';
import * as TextItems from 'components/input/Text';
import { MstDataContext } from 'contexts/Mst';
import { formatNumber } from 'functions/index';
import { useContext } from 'react';
import { ItemSelectProps, ItemTextFieldProps } from 'types';
import { tProjectPriceDetail } from 'types/project';

export const Kbn = ({
  label = '区分',
  name = 'kbn',
  value,
  cbValueChange,
}: ItemSelectProps) => {
  return (
    <SelectItems.PriceDetailKbn
      label={label}
      name={name}
      value={value}
      cbValueChange={cbValueChange}
    />
  );
};

export const KbnLabel = ({ value }: { value: number | undefined }) => {
  const { SYSTEM } = useContext(MstDataContext);

  if (!SYSTEM) return <Loading flg={true} />;

  if (value === SYSTEM.project.price_detail.detail) {
    return <Typography>基本運賃</Typography>;
  }
  if (value === SYSTEM.project.price_detail.separate) {
    return <Typography>割増料金</Typography>;
  }
  if (value === SYSTEM.project.price_detail.expenses) {
    return <Typography>経費</Typography>;
  }
  if (value === SYSTEM.project.price_detail.other) {
    return <Typography>その他</Typography>;
  }

  return <Typography>不明</Typography>;
};

export const Name = ({
  label = '名称',
  name = 'name',
  value,
  cbValueChange,
}: ItemTextFieldProps) => {
  return (
    <TextItems.Name
      label={label}
      name={name}
      value={value}
      cbValueChange={cbValueChange}
    />
  );
};

export const PriceLabel = ({
  price_unit,
  quantity,
}: {
  price_unit: number;
  quantity: number;
}) => {
  return <Typography>{formatNumber(price_unit * quantity)}</Typography>;
};

export const PriceTax = ({
  price,
  tax_id,
}: {
  price: tProjectPriceDetail['price'];
  tax_id: tProjectPriceDetail['tax_id'];
}) => {
  const { SYSTEM } = useContext(MstDataContext);

  if (!price) {
    return <Typography></Typography>;
  }

  const tax = SYSTEM?.tax.list.find((tax) => tax.id === tax_id)?.tax || 0;

  const taxPrice = price * (tax * 100);

  return <Typography>{formatNumber(price + taxPrice)}</Typography>;
};

export const Memo = ({
  label = '備考',
  name = 'memo',
  value,
  cbValueChange,
}: ItemTextFieldProps) => {
  return (
    <TextItems.Memo
      label={label}
      name={name}
      row={1}
      value={value}
      cbValueChange={cbValueChange}
    />
  );
};
