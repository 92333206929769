import { Add, Remove } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { strDateCustom, strDateTime } from 'functions/time';
import React from 'react';

interface DateInputProps {
  type?: 'date' | 'datetime-local' | 'time';
  label?: string;
  value: string;
  name: string;
  onDateChange: (newDate: string) => void;
  showSpinButtons?: boolean; // スピンボタンの表示を制御
  innerLabel?: boolean;
}

export const DateInput: React.FC<DateInputProps> = ({
  type = 'date',
  label = '',
  value,
  name,
  onDateChange,
  showSpinButtons = true, // デフォルトでスピンボタンをオン
  innerLabel = false,
}) => {
  const daysJP = ['日', '月', '火', '水', '木', '金', '土'];

  const formatDateWithDay = (dateStr: string): string => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return dateStr; // 無効な日付はそのまま
    /*
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    */
    const dayOfWeek = daysJP[date.getDay()];
    return `(${dayOfWeek})`;
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (onDateChange) {
      onDateChange(newValue);
    }
  };

  const incrementDate = () => {
    if (value === '') {
      value = new Date().toISOString().slice(0, type === 'date' ? 10 : 16);
    }
    const date = new Date(value);
    date.setDate(date.getDate() + 1);
    const newValue = date.toISOString().slice(0, type === 'date' ? 10 : 16);
    //setDateValue(newValue);
    if (onDateChange) {
      onDateChange(date.toISOString());
    }
  };

  const decrementDate = () => {
    if (value === '') {
      value = new Date().toISOString().slice(0, type === 'date' ? 10 : 16);
    }
    const date = new Date(value);
    date.setDate(date.getDate() - 1);
    const newValue = date.toISOString().slice(0, type === 'date' ? 10 : 16);
    //setDateValue(newValue);
    if (onDateChange) {
      onDateChange(date.toISOString());
    }
  };

  console.log('DateInput', value);
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <TextField
        type={type}
        value={strDateCustom(value)}
        name={name}
        size="small"
        fullWidth
        hiddenLabel={innerLabel}
        label={innerLabel ? label : undefined}
        sx={{ minWidth: '230px' }}
        onChange={handleDateChange}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ position: 'absolute', left: '7rem' }}
            >
              <Typography variant="body1" color={'black'}>
                {formatDateWithDay(value)}
              </Typography>
            </InputAdornment>
          ),
          endAdornment: showSpinButtons ? (
            <InputAdornment position="end">
              <IconButton onClick={decrementDate} edge="end">
                <Remove />
              </IconButton>
              <IconButton onClick={incrementDate} edge="end">
                <Add />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </>
  );
};

export const Datetime: React.FC<DateInputProps> = ({
  label = '',
  value,
  name,
  onDateChange,
  showSpinButtons = true, // デフォルトでスピンボタンをオン
}: DateInputProps) => {
  const handleDateChange = (newDate: string) => {
    //log.debug('newDate', newDate);
    // 入力日のに設定
    const inputDateTime = new Date(`${newDate} 00:00:00`);
    if (inputDateTime.toString() === 'Invalid Date') {
      return;
    }
    onDateChange(`${newDate} 00:00:00`);
  };
  //log.debug("values[index].datetime", values[index].datetime);
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <DateInput
        type="time"
        value={strDateTime(new Date(value))}
        name={name}
        onDateChange={handleDateChange}
        showSpinButtons={showSpinButtons} // スピンボタンのオンオフを制御
      />
    </>
    /*
    <TableCellTextField
      props={{
        type: "date",
        name: "waypoints." + index + ".datatime",
        value: changeFromDatetimeToInputTypeDate(
          new Date(values[index].datetime)
        ),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          // 入力日の23:59:59に設定
          // 入力日の23:59:59に設定
          const inputDate = e.target.value;
          const inputDateTime = new Date(inputDate + "T23:59:59");
          if (inputDateTime.toString() === "Invalid Date") {
            return;
          }
          onChange(inputDateTime.toISOString(), index, "datetime", setValues);
        },
      }}
    />
    */
  );
};
