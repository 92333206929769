import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { FlexBox, FlexColumnBox } from 'atoms/Box';
import * as ClosingComponent from 'components/closing/Index';
import * as ciBtn from 'components/customInvocie/Button';
import InvoiceTable from 'components/customInvocie/Table';
import * as NumberItems from 'components/input/Number';
import * as TextItems from 'components/input/Text';
import { initInvoice } from 'const/invoice';
import { MstDataContext } from 'contexts/Mst';
import ContentFrame from 'frames/ContentsFrame';
import { formatNumber } from 'functions';
import { postDetailsInfo } from 'functions/api/customInvoice';
import { getInvoiceDetailList } from 'functions/api/invoice';
import { getCompany } from 'functions/api/mst';
import { sumRecordValues } from 'functions/array';
import { useKeyedObject, useObjectList } from 'functions/useReducer/customHook';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tOrder } from 'types/index';
import { tCustomInvoiceEdit, tDetailsInfo, tInvoice } from 'types/invoice';
import { tKeyedObjectReturn, tObjectListReturn } from 'types/useReducer';

const Main = () => {
  const { cId } = useParams();
  const { SYSTEM } = useContext(MstDataContext);

  const edit = useKeyedObject<tInvoice>(initInvoice);

  const details = useObjectList<tCustomInvoiceEdit>([]);
  const [triggerUpdateList, setTriggerUpdateList] = useState<string>('');

  const updateList = () => {
    const filter = {
      c_id: Number(cId),
      status: SYSTEM.invoice.custom.status.edit.id,
    };
    const order: tOrder[] = [
      { field: 'date', direction: 'asc' },
      { field: 'id', direction: 'asc' },
    ];

    const cbs = ({
      data,
      filter,
    }: {
      data: tCustomInvoiceEdit[];
      filter: any;
    }) => {
      details.setList(data);
    };

    const cbe = () => {
      // ページ番号が指定されている場合
      // navigate('/paperwork/custom-invoice/1');
    };

    // データ取得処理
    getInvoiceDetailList({
      filter: filter,
      order: order,
      withs: [],
      callbackSuccess: cbs,
      callbackError: cbe,
    });
  };

  useEffect(() => {
    getCompany(Number(cId))
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('データの取得に失敗しました');
        }

        const data = res.data;
        edit.updateItems({
          c_id: Number(data.id),
          c_invoice_no: data.invoice_no,
          c_name: data.name,
          c_postal_code: data.postal_code,
          c_address1: data.address1,
          c_address2: data.address2,
        });
      })
      .catch((err) => {
        console.error(err);
        alert('取引先情報の取得に失敗しました');
      });
    edit.updateItem('c_id', Number(cId));
    updateList();
  }, []);

  // リスト更新トリガー
  useEffect(() => {
    updateList();
  }, [triggerUpdateList]);

  useEffect(() => {
    const cbs = (data: tDetailsInfo) => {
      const tax = sumRecordValues(data.taxes);
      const detailCount = sumRecordValues(data.detailCountForCtt);

      edit.updateItems({
        price: data.price,
        tax: tax,
        tax_exempt: data.pricesForTax[SYSTEM.tax.exempt.id] || 0,
        amount: data.price + tax + (edit.list.tax_adjust || 0),
        detail_count: detailCount,
      });
    };

    const cbe = () => {
      alert('データの取得に失敗しました');
    };

    // detailsのidを配列で取得
    const ids = details.list.map((v: tCustomInvoiceEdit) => v.id);
    if (ids.length === 0 || !cId) return;
    postDetailsInfo({ c_id: Number(cId), ids: ids }, cbs, cbe);
  }, [details.list]);

  return (
    <ContentFrame
      HeadContent={
        <FlexColumnBox>
          <FlexBox>
            <HeadContent
              values={edit.list}
              list={details.list}
              triUpdateList={setTriggerUpdateList}
            />
          </FlexBox>
          <EditContent values={edit} />
        </FlexColumnBox>
      }
      MainContent={<DetailsContent list={details} />}
      SubContent={<></>}
    ></ContentFrame>
  );
};

export default Main;

interface MainContentProps {
  values: tKeyedObjectReturn<tInvoice>;
}
const EditContent = ({ values }: MainContentProps) => {
  const theme = useTheme();
  return (
    <FlexBox width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{`${values.list.c_name}(${values.list.c_id})`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box maxWidth={'600px'}>
            <TextItems.Main
              label="タイトル"
              value={values.list.title}
              cbValueChange={(val) => values.updateItem('title', val)}
            />
          </Box>
        </Grid>
        <ClosingComponent.CompanyPostalCode
          value={values.list.c_postal_code}
          cbs={(val: string) => {
            values.updateItem('c_postal_code', val.toString());
          }}
        />
        <ClosingComponent.CompanyAddress1
          value={values.list.c_address1}
          cbs={(val) => values.updateItem('c_address1', val)}
        />
        <ClosingComponent.CompanyAddress2
          value={values.list.c_address2}
          cbs={(val) => values.updateItem('c_address2', val)}
        />
        <Grid item xs={12} md={6}>
          <TableContainer
            component={Paper}
            sx={{ maxWidth: theme.breakpoints.values.sm }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>合計</TableCell>
                  <TableCell>消費税額</TableCell>
                  <TableCell>非課税</TableCell>
                  <TableCell>調整金</TableCell>
                  <TableCell>請求金額</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography className="number">
                      {values.list.price &&
                        formatNumber(String(values.list.price), false)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className="number">
                      {values.list.tax &&
                        formatNumber(String(values.list.tax), false)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className="number">
                      {values.list.tax_exempt &&
                        formatNumber(String(values.list.tax_exempt), false)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <NumberItems.Main
                      value={values.list.tax_adjust}
                      cbValueChange={(val) =>
                        values.updateItems({
                          tax_adjust: !val ? 0 : val,
                          amount:
                            (val || 0) + values.list.price + values.list.tax,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Typography className="number">
                      {values.list.amount &&
                        formatNumber(String(values.list.amount), false)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </FlexBox>
  );
};

const HeadContent = ({
  values,
  list,
  triUpdateList,
}: {
  values: tInvoice;
  list: tCustomInvoiceEdit[];
  triUpdateList: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const ids = list.map((v: tCustomInvoiceEdit) => v.id);

  return (
    <>
      <>
        <Typography variant="h5"></Typography>
        <Typography variant="h5">{`(${values.c_id})${values.c_name}`}</Typography>
      </>

      {values.c_id && (
        <>
          <ciBtn.VieModalEdit
            callbackSuccess={() => {
              triUpdateList(new Date().toISOString());
            }}
            cId={values.c_id}
          />
          <ciBtn.MakeInvoice
            callbackSuccess={(data) => {
              alert('請求書を作成しました');
              triUpdateList(new Date().toISOString());
            }}
            iv={values}
            ids={ids}
          />
        </>
      )}
    </>
  );
};

interface SubContentProps {
  list: tObjectListReturn<tCustomInvoiceEdit>;
}
const DetailsContent = ({ list }: SubContentProps) => {
  return (
    <Box sx={{ minWidth: '1200px', height: '100%' }}>
      <InvoiceTable
        list={list.list}
        handleDeleteDetail={(data: tCustomInvoiceEdit) => {
          if (!data.id) return;

          for (let i = 0; i < list.list.length; i++) {
            if (list.list[i].id === data.id) {
              list.removeItem(i);
              break; // ここでループを抜ける
            }
          }
        }}
      />
    </Box>
  );
};
