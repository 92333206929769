import { TableCell, TableRow, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import * as Btn from 'components/customInvocie/Button';
import TableFrame from 'frames/TableFrame';
import { formatNumber } from 'functions/index';
import { tCustomInvoiceEdit } from 'types/invoice';

interface props {
  list: tCustomInvoiceEdit[];
  handleDoubleClickPjId?: (pjId: number | undefined) => void;
  handleDeleteDetail?: (data: tCustomInvoiceEdit) => void;
}

const Main = ({ list, handleDeleteDetail }: props) => {
  return (
    <TableFrame
      HeadContent={<></>}
      TableHeaderRows={<Head />}
      TableBodyRows={
        <Body list={list} handleDeleteDetail={handleDeleteDetail} />
      }
      SubContent={<></>}
    />
  );
};

export default Main;

export const Head = () => {
  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell>No</TableCell>
      <TableCell>取引区分</TableCell>
      <TableCell>対象</TableCell>
      <TableCell>日付</TableCell>
      <TableCell>担当</TableCell>
      <TableCell>車番</TableCell>
      <TableCell>シャーシ</TableCell>
      <TableCell>発地</TableCell>
      <TableCell>着地</TableCell>
      <TableCell>品名</TableCell>
      <TableCell>重量</TableCell>
      <TableCell>数量</TableCell>
      <TableCell>単価</TableCell>
      <TableCell>金額</TableCell>
      <TableCell>割増料金</TableCell>
      <TableCell>その他料金</TableCell>
      <TableCell>備考</TableCell>
      <TableCell>案件管理番号</TableCell>
    </TableRow>
  );
};

export const Body = ({
  list,
  handleDoubleClickPjId,
  handleDeleteDetail,
}: props) => {
  return (
    <>
      {list.map((detail: tCustomInvoiceEdit, index: number) => {
        if (!detail.id) return null;
        // 車番とシャーシにIDがある場合は名称をナンバーのみに変換する
        return (
          <TableRow key={`invoice-list-${detail.id}`}>
            <TableCell>
              <Btn.Delete
                id={detail.id}
                callbackSuccess={(data: tCustomInvoiceEdit) => {
                  if (handleDeleteDetail) {
                    handleDeleteDetail(data);
                  }
                }}
              />
            </TableCell>
            <TableCell>
              <Typography className="number">{index + 1}</Typography>
            </TableCell>
            <TableCell>
              <Typography></Typography>
            </TableCell>
            <TableCell>
              <Checkbox checked={true} readOnly />
            </TableCell>
            <TableCell>
              <Typography>
                {new Date(detail.date).toLocaleDateString()}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{detail.p_name}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{detail.vehicle_name}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{detail.trailer_name}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{detail.load_name}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{detail.unload_name}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{detail.luggage_name}</Typography>
            </TableCell>
            <TableCell>
              <Typography className="number">
                {detail.weight && formatNumber(String(detail.weight), false)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="number">
                {detail.quantity && formatNumber(String(detail.quantity), true)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="number">
                {detail.price_unit &&
                  formatNumber(String(detail.price_unit), false)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="number">
                {detail.price ? formatNumber(String(detail.price), false) : '0'}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="number">
                {detail.price_separate &&
                  formatNumber(String(detail.price_separate), false)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="number">
                {detail.price_other &&
                  formatNumber(String(detail.price_other), false)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{detail.memo}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{`C-${detail.id}`}</Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
