import { tPjMemo } from 'types/project';

type tProjectStatus = {
  id: number;
  label: string;
};
export const projectStatusQuotation: tProjectStatus = {
  id: 0,
  label: '見積中',
};

export const projectStatusOrdered: tProjectStatus = {
  id: 10,
  label: '受注済',
};

export const projectStatusInstrunctionCreated: tProjectStatus = {
  id: 20,
  label: '運行指示作成済み',
};

export const projectStatusDelivered: tProjectStatus = {
  id: 30,
  label: '運送済み',
};

export const projectStatusPending: tProjectStatus = {
  id: 40,
  label: '処理待ち',
};

export const projectStatusRemant: tProjectStatus = {
  id: 41,
  label: '差戻し',
};

export const projectStatusChecked: tProjectStatus = {
  id: 49,
  label: 'チェック済み',
};

export const projectStatusClosed: tProjectStatus = {
  id: 50,
  label: '締め処理済み',
};

export const projectStatusCharged: tProjectStatus = {
  id: 59,
  label: '請求済み',
};

export const projectStatusCompleted: tProjectStatus = {
  id: 90,
  label: '完了系',
};

export const projectStatusLost: tProjectStatus = {
  id: 99,
  label: '失注',
};

export const projectStatus: tProjectStatus[] = [
  projectStatusQuotation,
  projectStatusOrdered,
  projectStatusInstrunctionCreated,
  projectStatusDelivered,
  projectStatusChecked,
  projectStatusClosed,
  projectStatusCompleted,
  projectStatusLost,
];

/**
 * 案件入力画面でのステータス
 */
export const projectEditStatus: tProjectStatus[] = [
  projectStatusQuotation,
  projectStatusOrdered,
];

export const closingStatus: tProjectStatus[] = [
  // 配車機能運用開始までは受注済み、輸送中も締め処理可能
  projectStatusOrdered,
  projectStatusInstrunctionCreated,

  // 輸送済みから締め処理以前までのステータス
  projectStatusDelivered,
  projectStatusPending,
  projectStatusRemant,
  projectStatusChecked,
];

export const initPjMemo: tPjMemo = {
  id: undefined,
  memo: '',
  flg_pj: false,
  c_id: undefined,
};
