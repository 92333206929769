import { initTradeType } from 'const/comapny/index';
import { tCompany } from 'types/company';

export const initTradeTypes = (c_id: tCompany['id'] = undefined) => {
  return Array(5)
    .fill(null)
    .map((_, i) => {
      return { ...initTradeType, c_id: c_id };
    });
};
