/**
 * 単価計算：単価入力
 * @param val
 * @param quantity
 * @param price
 * @returns [unit, quantity, price]
 */
export const calcPriceFromUnit = (
  val: number | null,
  quantity: number | undefined | null,
  price: number | undefined | null
) => {
  if (!val || !quantity) {
    //val = val;
    price = null;
  } else {
    // 数量も単価もある場合は算出（上書き）
    price = quantity * val;
  }

  return [val, quantity, price];
};

/**
 * 数量入力イベント
 * @param val
 * @param unit
 * @param price
 * @returns [unit, quantity, price]
 */
export const calcPriceFromQuantity = (
  val: number | undefined | null,
  unit: number | undefined | null,
  price: number | undefined | null
) => {
  if (!val || !unit) {
    price = null;
  } else {
    // 数量も単価もある場合は算出（上書き）
    price = unit * val;
  }

  return [unit, val, price];
};

/**
 * 金額入力イベント
 * @param val
 * @param unit
 * @param quantity
 * @returns [unit, quantity, price]
 */
export const calcPriceFromPrice = (
  val: number | undefined | null,
  unit: number | undefined | null,
  quantity: number | undefined | null
) => {
  // 消した場合はそれで終わり
  if (val) {
    // 単価と数量の両方がからの場合は数量１とする
    if (!unit && !quantity) {
      // 数量も単価もわからない場合は単価＝金額とする
      quantity = 1;
      unit = val;
    } else if (unit && !quantity) {
      // 単価がある場合は数量を
      quantity = val / unit;
    } else if (!unit && quantity) {
      // 単価が無くて数量がある場合は単価を算出
      unit = val / quantity;
    } else {
      // 単価も数量も入力がある場合
      // それでも金額を変更した場合はそれを受け入れる
    }
  }

  return [unit, quantity, val];
};
