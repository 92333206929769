import { Page, StyleSheet, View } from '@react-pdf/renderer';
import { CustomText, EmphaText, SmallText } from 'components/react-pdf/Text';
import { MstDataContext } from 'contexts/Mst';
import { chunkArray, sumRecordValues } from 'functions/array';
import { formatNumber, truncateString } from 'functions/index';
import { priceDiffTax } from 'functions/invocie/math';
import { formatMonthDay, strDateTimeOrigin } from 'functions/time';
import { useContext } from 'react';
import { Footer } from 'sheets/invoice/Cover';
import { tInvoice, tInvoiceDetail, tInvoiceItem } from 'types/invoice';

interface Props {
  invoice: tInvoice;
  details: tInvoiceDetail[];
  item: tInvoiceItem;
}

export default function Main({ invoice, details, item }: Props) {
  // 1ページ30行にするため30行単位にdetailを分割
  const pages = chunkArray(details, 34);

  // 最終ページはあまり4行必要

  return (
    <>
      {pages.map((pageData, pageIndex) => (
        <Page
          key={pageIndex}
          size="A4"
          orientation="landscape"
          style={styles.page}
        >
          <Header item={item} />
          <Table details={pageData} />
          {pages.length === pageIndex + 1 && (
            <CttTable
              item={item}
              flgBleak={pageData.length > 30 ? true : false}
            />
          )}
          <Footer
            invoice={invoice}
            totalPage={pages.length}
            currentPage={pageIndex + 1}
          />
        </Page>
      ))}
    </>
  );
}

const Header = ({ item }: { item: tInvoiceItem | null }) => {
  return (
    <View style={styles.header} fixed>
      {item?.ctt_name ? (
        <EmphaText>{`区分：${item.ctt_name} ${item.ctt_price_month ? `(月額：${formatNumber(item.ctt_price_month)})` : ``}`}</EmphaText>
      ) : (
        <EmphaText>{`区分：-`}</EmphaText>
      )}
      <CustomText style={{ textAlign: 'right' }}>
        {strDateTimeOrigin(new Date())}
      </CustomText>
    </View>
  );
};

const Table = ({ details }: { details: tInvoiceDetail[] }) => {
  const tableCellNo = { ...styles.tableCell, width: '5%' };
  const tableCellPerson = { ...styles.tableCell, width: '5%' };
  const tableCellVehicle = { ...styles.tableCell, width: '5%' };
  const tableCellTrailer = { ...styles.tableCell, width: '5%' };
  const tableCellLoad = { ...styles.tableCell, width: '10%' };
  const tableCellLUnload = { ...styles.tableCell, width: '10%' };
  const tableCellLuggage = { ...styles.tableCell, width: '10%' };
  const tableCellWeight = { ...styles.tableCell, width: '5%' };
  const tableCellPriceUnit = { ...styles.tableCell, width: '7%' };
  const tableCellQuantity = { ...styles.tableCell, width: '5%' };
  const tableCellPrice = { ...styles.tableCell, width: '5%' };
  const tableCellPriceSepa = { ...styles.tableCell, width: '5%' };
  const tableCellPriceOther = { ...styles.tableCell, width: '5%' };
  const tableCellMemo = { ...styles.tableCell, width: '12%' };
  const tableCellPjId = { ...styles.tableCell, width: '6%' };

  return (
    <>
      <View style={styles.detailsContainer}>
        <View style={styles.tableRow}>
          <SmallText style={tableCellNo}>日付</SmallText>
          <SmallText style={tableCellPerson}>担当</SmallText>
          <SmallText style={tableCellVehicle}>車番</SmallText>
          <SmallText style={tableCellTrailer}>シャーシ</SmallText>
          <SmallText style={tableCellLoad}>発地</SmallText>
          <SmallText style={tableCellLUnload}>着地</SmallText>
          <SmallText style={tableCellLuggage}>品名</SmallText>
          <SmallText style={tableCellWeight}>輸送量</SmallText>
          <SmallText style={tableCellQuantity}>数量/重量</SmallText>
          <SmallText style={tableCellPriceUnit}>単価</SmallText>
          <SmallText style={tableCellPrice}>金額</SmallText>
          <SmallText style={tableCellPriceSepa}>割増料金</SmallText>
          <SmallText style={tableCellPriceOther}>その他</SmallText>
          <SmallText style={tableCellMemo}>備考</SmallText>
          <SmallText style={tableCellPjId}>管理番号</SmallText>
        </View>
        {details.map((detail) => (
          <View
            key={`invoice-detail-${detail.iv_id}-${detail.id}`}
            style={styles.tableRow}
          >
            <SmallText style={tableCellNo}>
              {formatMonthDay(new Date(detail.date))}
            </SmallText>
            <SmallText style={tableCellPerson}>{detail.p_name}</SmallText>
            <SmallText style={tableCellVehicle}>
              {truncateString(detail.vehicle_name, 4)}
            </SmallText>
            <SmallText style={tableCellTrailer}>
              {truncateString(detail.trailer_name, 4)}
            </SmallText>
            <SmallText style={tableCellLoad}>
              {truncateString(detail.load_name, 8)}
            </SmallText>
            <SmallText style={tableCellLUnload}>
              {truncateString(detail.unload_name, 8)}
            </SmallText>
            <SmallText style={tableCellLuggage}>
              {truncateString(detail.luggage_name, 10)}
            </SmallText>
            <SmallText style={{ ...tableCellWeight, textAlign: 'right' }}>
              {detail.weight
                ? formatNumber(detail.weight.toString(), false)
                : ''}
            </SmallText>
            <SmallText style={{ ...tableCellQuantity, textAlign: 'right' }}>
              {detail.quantity ? formatNumber(detail.quantity.toString()) : ''}
            </SmallText>
            <SmallText style={{ ...tableCellPriceUnit, textAlign: 'right' }}>
              {detail.price_unit
                ? formatNumber(detail.price_unit.toString(), true)
                : ''}
            </SmallText>
            <SmallText style={{ ...tableCellPrice, textAlign: 'right' }}>
              {detail.price ? formatNumber(detail.price.toString(), false) : ''}
            </SmallText>
            <SmallText style={{ ...tableCellPriceSepa, textAlign: 'right' }}>
              {detail.price_separate
                ? formatNumber(detail.price_separate.toString(), false)
                : ''}
            </SmallText>
            <SmallText style={{ ...tableCellPriceOther, textAlign: 'right' }}>
              {detail.price_other
                ? formatNumber(detail.price_other.toString(), false)
                : ''}
            </SmallText>
            <SmallText style={tableCellMemo}>
              {truncateString(detail.memo, 10)}
            </SmallText>
            <SmallText style={tableCellPjId}>{detail.pj_id}</SmallText>
          </View>
        ))}
      </View>
    </>
  );
};

const CttTable = ({
  item,
  flgBleak,
}: {
  item: tInvoiceItem;
  flgBleak: boolean;
}) => {
  const { SYSTEM } = useContext(MstDataContext);
  const prices = priceDiffTax(item, SYSTEM.tax);

  return (
    <View
      style={{
        ...styles.detailsContainer,
        width: '50%',
        marginLeft: 'auto',
        ...(flgBleak ? ({ breakBefore: 'page' } as any) : {}), // ✅ 修正
      }}
    >
      <View style={styles.tableRow}>
        <CustomText>数</CustomText>
        <CustomText>小計</CustomText>
        <CustomText>内訳(10%)</CustomText>
        <CustomText>内訳(8%)</CustomText>
        <CustomText>内訳(非課税)</CustomText>
      </View>
      <View style={styles.tableRow}>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber((item.detail_count || 0).toString(), false)}
        </CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber((sumRecordValues(prices) || 0).toString(), false)}
        </CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(prices[SYSTEM.tax[10].id].toString(), false)}
        </CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(prices[SYSTEM.tax[8].id].toString(), false)}
        </CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(prices[SYSTEM.tax.exempt.id].toString(), false)}
        </CustomText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 15,
    fontSize: 12,
    position: 'relative',
    width: '100%',
  },
  header: {
    textAlign: 'left',
    fontWeight: 'bold',
    width: '100%',
    height: 20,
    padding: 10,
    top: 0,
    left: 0,
  },
  detailsContainer: {
    marginTop: 15,
    width: '100%',
    height: 'auto',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    borderBottom: '1px solid black',
    padding: 1,
    whiteSpace: 'nowrap', // テキストを1行で表示
    textAlign: 'center',
  },
  tableCell: {
    overflow: 'hidden',
    marginLeft: 1,
    marginRight: 1,
    //border: '1px solid #000',
    textOverflow: 'ellipsis', // 切り捨てに「...」を表示
    whiteSpace: 'nowrap', // テキストを1行で表示
  },
});
