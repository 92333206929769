import {
  GridItemNumberField,
  GridItemRowTextField,
  GridItemTextField,
} from 'components/GridItem';

interface GridItemFieldProps {
  value: any;
  size?: Record<string, number>;
  cbs: (val: any) => void;
}

export const Title = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemTextField
    label="タイトル"
    name="title"
    value={value}
    handleChangeValues={cbs}
  />
);

export const TotalAmount = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemNumberField
    label="合計金額"
    name="total_amount"
    value={value}
    cbHandleChange={cbs}
  />
);

export const Tax = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemNumberField
    label="消費税額"
    name="tax"
    value={value}
    cbHandleChange={cbs}
  />
);

export const BillingAmount = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemNumberField
    label="請求金額"
    name="billing_amount"
    value={value}
    cbHandleChange={cbs}
  />
);

export const DetailCount = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemNumberField
    label="案件件数"
    name="detail_count"
    value={value}
    cbHandleChange={cbs}
  />
);

export const CompanyInvoiceNo = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemTextField
    label="インボイス番号"
    name="c_invoice_no"
    value={value}
    handleChangeValues={cbs}
  />
);

export const CompanyName = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemTextField
    label="会社名"
    name="c_name"
    value={value}
    handleChangeValues={cbs}
  />
);

export const CompanyPostalCode = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemTextField
    label="郵便番号"
    name="c_postal_code"
    value={value}
    handleChangeValues={cbs}
  />
);

export const CompanyAddress1 = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemTextField
    label="住所1"
    name="c_address1"
    value={value}
    size={{ xs: 12, sm: 6, lg: 4 }}
    handleChangeValues={cbs}
  />
);

export const CompanyAddress2 = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemTextField
    label="住所2"
    name="c_address2"
    value={value}
    size={{ xs: 12, sm: 6, lg: 4 }}
    handleChangeValues={cbs}
  />
);

export const ProjectInChargeName = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemTextField
    label="担当者名"
    name="pj_in_charge_name"
    value={value}
    handleChangeValues={cbs}
  />
);

export const ProjectMemo = ({ value, cbs }: GridItemFieldProps) => (
  <GridItemRowTextField
    name="memo"
    label=""
    row={2}
    value={value}
    handleChangeValues={cbs}
  />
);
