import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { CustomText, EmphaText } from 'components/react-pdf/Text';
import { KeyDefined } from 'const/index';
import { MstDataContext } from 'contexts/Mst';
import { groupBy } from 'functions/array';
import { formatNumber } from 'functions/index';
import { priceDiffTax } from 'functions/invocie/math';
import { strDateTimeOrigin } from 'functions/time';
import React, { Component, ReactNode, useContext } from 'react';
import SheetType01 from 'sheets/invoice/Type01';
import { tInvoice } from 'types/invoice';
import { tSelfInformation } from 'types/mst';

interface Props {
  invoice: tInvoice;
  mycompany: tSelfInformation;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    // `children` が変わった場合、エラー状態をリセット
    if (prevProps.children !== this.props.children) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      console.log('PDF作成に失敗しました (エラーログを確認してください)');
      return <div>PDF作成に失敗しました (エラーログを確認してください)</div>;
    }

    return this.props.children;
  }
}

// PDFビューアコンポーネント
const PDFPreview = ({ invoice, mycompany }: Props) => {
  console.log('PDFPreview', [invoice, mycompany]);

  if (!invoice || !mycompany) {
    return <p>データを読み込み中...</p>;
  }

  return (
    <ErrorBoundary>
      <Main
        key={JSON.stringify(invoice)}
        invoice={invoice}
        mycompany={mycompany}
      />
    </ErrorBoundary>
  );
};

export default PDFPreview;

const Main = ({ invoice, mycompany }: Props) => {
  const details = invoice.details || [];
  const items = invoice.items || [];

  // 明細をsheet_nameで分割
  const detailsCtts = groupBy(details, 'ctt_id');

  return (
    <PDFViewer width="100%" height="600px">
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <Header />
          <Amont invoice={invoice} />
          <Invoice invoice={invoice} mycompany={mycompany} />
          <Details info={invoice} />
          <Footer invoice={invoice} />
        </Page>

        {/* シート名で分割 */}
        {items.map((item) => {
          const ctt_id = item.ctt_id || KeyDefined;

          switch (item.ctt_sheet_name) {
            case 'type1':
            default:
              return (
                <SheetType01
                  key={`SheetType01-${item.iv_id}-${item.ctt_id}`}
                  invoice={invoice}
                  details={detailsCtts[ctt_id]}
                  item={item}
                />
              );
          }
        })}
      </Document>
    </PDFViewer>
  );
};

const Header = () => {
  return (
    <View style={styles.header} fixed>
      <CustomText style={{ textAlign: 'right' }}>
        {strDateTimeOrigin(new Date())}
      </CustomText>
    </View>
  );
};

const Invoice = ({
  invoice,
  mycompany,
}: {
  invoice: tInvoice;
  mycompany: tSelfInformation;
}) => {
  return (
    <View style={styles.invoiceContainer}>
      <View style={styles.invoiceSection}>
        <CustomText>{`〒${invoice.c_postal_code}`}</CustomText>
        <CustomText>{`${invoice.c_address1}`}</CustomText>
        {invoice.c_address2 && (
          <CustomText>{`${invoice.c_address2}`}</CustomText>
        )}
        <View
          style={{
            marginTop: 8,
            marginLeft: 8,
            flexDirection: 'row', // flexFlowの代わりにflexDirectionを使用
            flexWrap: 'nowrap', // 必要に応じて追加
            justifyContent: 'space-between', // 配置を調整
            alignItems: 'center', // 縦方向の配置を調整
          }}
        >
          <CustomText>{invoice.c_name}</CustomText>
          <EmphaText>御中</EmphaText>
        </View>
        <CustomText
          style={{ textAlign: 'center' }}
        >{`[${invoice.c_id}]`}</CustomText>
      </View>
      <View style={styles.invoiceSection}>
        <CustomText style={styles.invoiceTitle}>請求書</CustomText>
        <CustomText
          style={{ textAlign: 'center' }}
        >{`${invoice.title}`}</CustomText>
      </View>
      <View style={{ ...styles.invoiceSection, marginTop: '50px' }}>
        <CustomText>{`T ${mycompany.corporate_number}`}</CustomText>
        <CustomText>{mycompany.name}</CustomText>
        <CustomText>{`〒${mycompany.postal_code}`}</CustomText>
        <CustomText>{mycompany.address1}</CustomText>
        <CustomText>{`(TEL)${mycompany.tel}`}</CustomText>
        <CustomText>{`(FAX)${mycompany.fax}`}</CustomText>
        <CustomText>{`${mycompany.billing_info}`}</CustomText>
      </View>
    </View>
  );
};

export const Footer = ({
  invoice,
  totalPage,
  currentPage,
}: {
  invoice: tInvoice;
  totalPage?: number;
  currentPage?: number;
}) => {
  return (
    <View style={styles.footer} fixed>
      {totalPage && currentPage && (
        <Text style={styles.footerPage}>{`${currentPage} / ${totalPage}`}</Text>
      )}
      <Text style={styles.sheetName}>{invoice.invoice_number}</Text>
    </View>
  );
};

const Amont = ({ invoice }: { invoice: tInvoice }) => {
  return (
    <View
      style={[
        styles.invoiceContainer,
        styles.tableRow,
        {
          position: 'absolute',
          top: '150px',
          left: '30px',
          width: '50%',
        },
      ]}
    >
      <View style={{ width: '24%' }}>
        <CustomText>請求金額</CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(invoice.price.toString(), false)}
        </CustomText>
      </View>
      <View style={{ width: '24%' }}>
        <CustomText>消費税</CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(invoice.tax.toString(), false)}
        </CustomText>
      </View>
      <View style={{ width: '24%' }}>
        <CustomText>非課税</CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(
            (invoice.tax_exempt + invoice.tax_adjust).toString(),
            false
          )}
        </CustomText>
      </View>
      <View style={{ width: '24%' }}>
        <CustomText>合計金額</CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(invoice.amount.toString(), false)}
        </CustomText>
      </View>
    </View>
  );
};

const Details = ({ info }: { info: tInvoice }) => {
  const { SYSTEM } = useContext(MstDataContext);
  const tableCellNo = { ...styles.tableCell, width: '5%' };
  const tableCellCtt = { ...styles.tableCell, width: '10%' };
  const tableCellCount = { ...styles.tableCell, width: '7%' };
  const tableCellPrice = { ...styles.tableCell, width: '18%' };
  const tableCellPrice10 = { ...styles.tableCell, width: '18%' };
  const tableCellLPrice08 = { ...styles.tableCell, width: '18%' };
  const tableCellPriceOther = { ...styles.tableCell, width: '18%' };

  return (
    <View style={styles.detailsContainer}>
      <View style={styles.tableRow}>
        <CustomText style={tableCellNo}>NO</CustomText>
        <CustomText style={tableCellCtt}>取引区分</CustomText>
        <CustomText style={tableCellCount}>数</CustomText>
        <CustomText style={tableCellPrice}>小計</CustomText>
        <CustomText style={tableCellPrice10}>内訳(10%)</CustomText>
        <CustomText style={tableCellLPrice08}>内訳(8%)</CustomText>
        <CustomText style={tableCellPriceOther}>内訳(非課税)</CustomText>
      </View>
      {info.items?.map((item, index) => {
        const prices = priceDiffTax(item, SYSTEM.tax);

        return (
          <View
            key={`invoice-cover-${item.iv_id}-${item.ctt_id}-${index}`}
            style={styles.tableRow}
          >
            {/* NO */}
            <CustomText style={tableCellNo}>{`${index + 1}`}</CustomText>
            {/* 取引区分 */}
            <CustomText style={tableCellCtt}>
              {`${item.ctt_name || '-'}`}
            </CustomText>
            {/* 数 */}
            <CustomText style={{ ...tableCellCount, textAlign: 'right' }}>
              {`${formatNumber((item.detail_count || 0).toString(), false)}`}
            </CustomText>
            {/* 小計 */}
            <CustomText
              style={{ ...tableCellPrice, textAlign: 'right' }}
            >{`${formatNumber((item.grand_total + (item.ctt_price_month || 0)).toString(), false)}`}</CustomText>
            {/* 内訳(10%) */}
            <CustomText style={{ ...tableCellPrice10, textAlign: 'right' }}>
              {`${formatNumber(prices[SYSTEM.tax[10].id].toString(), false)}`}
            </CustomText>
            {/* 内訳(8%) */}
            <CustomText
              style={{ ...tableCellLPrice08, textAlign: 'right' }}
            >{`${formatNumber(prices[SYSTEM.tax[8].id].toString(), false)}`}</CustomText>
            {/* 内訳(非課税) */}
            <CustomText style={{ ...tableCellPriceOther, textAlign: 'right' }}>
              {`${formatNumber(prices[SYSTEM.tax.exempt.id].toString(), false)}`}
            </CustomText>
          </View>
        );
      })}
      {info.tax_adjust && (
        <View
          key={`invoice-cover-${info.id}-tax_adjust`}
          style={styles.tableRow}
        >
          <CustomText
            style={tableCellNo}
          >{`${info.items?.length || 0 + 1}`}</CustomText>
          <CustomText style={tableCellCtt}>調整金</CustomText>
          <CustomText style={tableCellCount}>{``}</CustomText>
          <CustomText
            style={{ ...tableCellPrice, textAlign: 'right' }}
          >{`${formatNumber(info.tax_adjust.toString(), false)}`}</CustomText>
          <CustomText style={tableCellPrice10}>{``}</CustomText>
          <CustomText style={tableCellLPrice08}>{``}</CustomText>
          <CustomText
            style={{ ...tableCellPriceOther, textAlign: 'right' }}
          >{`${formatNumber(info.tax_adjust.toString(), false)}`}</CustomText>
        </View>
      )}
    </View>
  );
};

// スタイルシート
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    position: 'relative',
    width: '100%',
  },
  header: {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    padding: 10,
    top: 0,
    left: 0,
  },
  invoiceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    width: '100%',
  },
  invoiceSection: {
    width: '32%',
  },
  invoiceTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  detailsContainer: {
    marginTop: 15,
    width: '100%',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    borderBottom: '1px solid black',
    padding: 5,
    whiteSpace: 'nowrap', // テキストを1行で表示
    textAlign: 'center',
  },
  tableCell: {
    overflow: 'hidden',
    marginLeft: 1,
    marginRight: 1,
    //border: '1px solid #000',
    textOverflow: 'ellipsis', // 切り捨てに「...」を表示
    whiteSpace: 'nowrap', // テキストを1行で表示
  },
  footer: {
    marginTop: 20,
    textAlign: 'right',
    position: 'absolute',
    bottom: 5,
    right: 10,
    width: '100%',
    height: 20,
    fontSize: 6,
  },
  footerPage: {
    textAlign: 'center',
    width: '100%',
  },
  sheetName: {
    position: 'absolute',
    right: 0,
  },
});
