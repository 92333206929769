import { DeleteButton, PrimaryButton, SecondButton } from 'atoms/Button';
import Loading from 'atoms/Loading';
import Modal from 'atoms/Modal';
import ErrModal from 'components/error/Modal';
import { initInvoice } from 'const/invoice';
import { MstDataContext } from 'contexts/Mst';
import { deleteInvoice, getInvoice } from 'functions/api/invoice';
import { useKeyedObject } from 'functions/useReducer/customHook';
import { useContext, useEffect, useState } from 'react';
import PDFPreview from 'sheets/invoice/Cover';
import { tErrMsg } from 'types/index';
import { tInvoice } from 'types/invoice';

interface PDFDownloadProps {
  id: tInvoice['id'];
  defFlgShow?: boolean;
  callbackClose?: () => void;
}
export const InvoicePreview = ({
  id,
  defFlgShow = false,
  callbackClose = undefined,
}: PDFDownloadProps) => {
  const { selfInfomation, loading } = useContext(MstDataContext);
  const [showModal, setShowModal] = useState(false);
  const [flgLoading, setFlgLoading] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  /**
   * 請求書データ
   * 合計金額、消費税、請求金額を格納する
   * 調整金等もここ
   */
  const iv = useKeyedObject<tInvoice>(initInvoice);

  const getData = async () => {
    if (!id) return;

    const callbackGetData = (data: tInvoice) => {
      console.log(data);
      iv.setList(data);
      //setShowModal(true);
    };

    const callbackError = () => {
      alert('請求書の取得に失敗しました。');
    };

    await getInvoice(id, callbackGetData, callbackError);
  };

  const handleOpen = async () => {
    setFlgLoading(true);
    await getData();
    setFlgLoading(false);
  };
  const handleClose = () => {
    setShowModal(false);
    if (callbackClose) {
      callbackClose();
    }
  };

  useEffect(() => {
    if (iv.list && Object.keys(iv.list).length > 0 && iv.list.id === id) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [iv.list]); // `iv.list` が更新されたときにモーダルを開く

  useEffect(() => {
    if (defFlgShow) {
      handleOpen();
    }
  }, [id, defFlgShow]);

  if (!selfInfomation) return <Loading />;

  return (
    <>
      <Loading flg={flgLoading || loading} />
      <Modal
        width={'xl'}
        open={showModal}
        onClose={handleClose}
        title={'sheetName'}
        actions={
          <>
            <SecondButton
              onClick={() => {
                handleClose();
              }}
              label="閉じる"
            />
          </>
        }
      >
        {/* invoice データが正しく渡ることを保証 */}
        {iv.list.id === id ? (
          <PDFPreview invoice={iv.list} mycompany={selfInfomation} />
        ) : (
          <p>データを取得できませんでした。</p>
        )}
      </Modal>
      <PrimaryButton onClick={() => handleOpen()} label="PDF表示" />
    </>
  );
};

interface DeleteProps {
  callbackSuccess?: ((data: tInvoice) => void) | undefined;
  callbackError?: ((err: any) => void) | undefined;
  id: number;
}

export const DeleteData = ({
  callbackSuccess = undefined,
  callbackError = undefined,
  id,
}: DeleteProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    if (id) {
      const cbs = ({ data }: { data: tInvoice }) => {
        if (callbackSuccess) {
          callbackSuccess(data);
        }
      };
      const cbe = (err: any) => {
        if (callbackError) {
          callbackError(err);
        }
      };
      deleteInvoice({ id: id, callbackSuccess: cbs, callbackError: cbe });
    }
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <DeleteButton label="差戻し" onClick={handleClick} />
    </>
  );
};
