import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { formatNumber, toHalfWidth, toNumber } from 'functions/index';
import log from 'functions/logger';
import React, { FocusEvent, KeyboardEvent, useEffect, useState } from 'react';
import { ListItem } from 'types/index';

interface SmallTextFieldProps {
  label?: string;
  innerLabel?: boolean;
  value?: any;
  props: TextFieldProps; // ここでpropsを定義します
}

const Master: React.FC<TextFieldProps> = (props) => {
  return <TextField fullWidth {...props} />;
};

export const SmallTextField: React.FC<SmallTextFieldProps> = ({
  label,
  innerLabel = false,
  props,
}) => {
  // `value` が null または undefined の場合は "" に変換し、明示的に制御
  const processedProps = {
    ...props,
    value: props.value != null ? props.value.toString() : '',
  };
  return (
    <>
      {label && !innerLabel && <Typography variant="h6">{label}</Typography>}
      <Master
        {...processedProps}
        size="small"
        hiddenLabel={innerLabel}
        label={innerLabel ? label : undefined}
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
};

export const TableCellTextField: React.FC<SmallTextFieldProps> = ({
  label = undefined,
  props,
}) => {
  return <TextField fullWidth {...props} size="small" label={label || ''} />;
};

interface SelectTextFieldProps {
  label?: string;
  items: Record<string, string>[];
  selectedValue: string;
  props: TextFieldProps;
}
export const SelectTextField = ({
  label,
  items,
  selectedValue,
  props,
}: SelectTextFieldProps) => {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <TextField
        select
        value={selectedValue}
        variant="outlined"
        fullWidth
        {...props}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

interface AutocompleteTextFieldProps {
  freeSolo?: boolean;
  disableClearable?: boolean;
  label: string;
  options: Record<string, any>[];
  value: Record<string, any> | null;
  inputValue?: string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: ListItem
  ) => void;
}
export const AutocompleteTextField = ({
  freeSolo = false,
  disableClearable = false,
  label,
  options,
  value,
  inputValue = '',
  onChange,
}: AutocompleteTextFieldProps) => {
  if (!inputValue) {
    inputValue = value ? value.label : '';
  }
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <Autocomplete
        autoSelect={true}
        fullWidth={true}
        freeSolo={freeSolo}
        disableClearable={disableClearable}
        sx={{ padding: 0 }}
        getOptionLabel={(option) =>
          option.label ? String(option.label) : String(option.name)
        }
        value={value}
        inputValue={inputValue}
        onChange={(e, newValue) => {
          /* 2025-01-07 案件入力の積卸地のバグ対応時にコメントアウト
          log.debug('onChange', newValue);
          onChange(e, newValue);
          */
        }}
        onInputChange={(e, newInputValue) => {
          log.debug('onInputChange', newInputValue);
          if (newInputValue === 'undefined' || !newInputValue) {
            // クリアボタンが押されたとき
            if (e?.type === 'click') {
              newInputValue = ''; // クリアボタンクリック時の処理
            } else {
              const html = e.target as HTMLInputElement;
              // フォーカスが外れた時などの処理
              newInputValue = html.value;
            }
          }
          onChange(e, { id: 0, label: newInputValue } as ListItem);
        }}
        options={options}
        renderInput={(params) => (
          <SmallTextField label={undefined} props={params} />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        limitTags={5} // 最大表示数を5件に制限
      />
    </>
  );
};

interface RowTextFieldProps {
  name: string;
  label: string;
  value: string;
  handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  row?: number;
}
export function RowTextField({
  name,
  label,
  value,
  handleChangeValues,
  row = 4,
}: RowTextFieldProps) {
  return (
    <SmallTextField
      label={label}
      props={{
        name: name,
        value: value,
        onChange: handleChangeValues,
        multiline: true,
        rows: row,
      }}
    />
  );
}

interface NumberProps {
  name?: string;
  label?: string;
  value: number | undefined | null;
  cbHandleChangeValues?: (val: number | null) => void;
  setValues?: React.Dispatch<React.SetStateAction<any>>;
  //handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
  flgFloat?: boolean;
  disabled?: boolean;
  innerLabel?: boolean;
}
export const NumberField = React.memo(
  ({
    name = '',
    label = '',
    value,
    cbHandleChangeValues,
    setValues,
    flgFloat = false,
    disabled = false,
    innerLabel = false,
  }: NumberProps) => {
    const [viewVal, setViewVal] = useState<string>('');

    const handleChangeValues = (val: number | undefined | null) => {
      // 初期値をフォーマットして表示
      setViewVal(
        val || val === 0 ? formatNumber(val.toString(), flgFloat) : ''
      );
    };

    useEffect(() => {
      // 初期値をフォーマットして表示
      handleChangeValues(value);
    }, [value]);

    /**
     * フォーカスが外れたらフォーマットして表示
     * 同時に引数のコールバック関数を実行
     * @param e
     */
    const handleBlur = (val: string) => {
      val = toHalfWidth(val); // 全角を半角に変換

      // 数字に変換できるかをチェック
      const num = toNumber(val);

      /**
       * コールバック関数がある場合はコールバック関数を実行
       * ない場合はsetValuesを実行
       */
      if (cbHandleChangeValues) {
        cbHandleChangeValues(num);
      } else if (setValues) {
        setValues((prev: any) => {
          return { ...prev, [name]: num };
        });
      }

      // 小数点以下2桁にフォーマットして表示
      handleChangeValues(value);
    };

    return (
      <SmallTextField
        label={`${label}`}
        innerLabel={innerLabel}
        props={{
          name: name,
          value: viewVal,
          disabled: disabled,
          onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            // フォーカスが外れた時にフォーマットして表示
            handleBlur(e.target.value);
          },
          onFocus: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            // フォーカスが当たった時にフォーマットを解除
            setViewVal((value || '').toString());
          },
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setViewVal(e.target.value);
          },
          onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => {
            const char = e.key;
            if (char === 'Enter') {
              const temp = e as unknown as FocusEvent<
                HTMLInputElement | HTMLTextAreaElement
              >;
              // フォーカスを外す
              temp.target.blur();
            }
          },
        }}
      />
    );
  }
);
