import { DeleteButton, PrimaryButton, SecondButton } from 'atoms/Button';
import ErrModal from 'components/error/Modal';
import PJModal from 'components/project/Modal';
import SearchModal, {
  callbackProps as SearchModalCallbackProps,
} from 'components/project/Search';
import { waypointKbnLoad, waypointKbnUnload } from 'const/index';
import {
  deleteProject,
  storeProject,
  updateProject,
} from 'functions/api/project';
import { changeToState } from 'functions/error/';
import React, { useState } from 'react';
import { tErrMsg } from 'types/index';
import {
  initialWaypoint,
  initProject,
  tProject,
  tProjectPriceDetail,
  tProjectSearch,
  tWaypoints,
} from 'types/project';

interface MainProps {
  projectInfo: tProject;
  waypoints: tWaypoints;
  priceDetails?: tProjectPriceDetail[];
  callbackNomal?: (data: tProject) => void;
  callbackError?: (data: tProject) => void;
}

/**
 * 新規登録処理
 * @param flgReuse
 * @returns
 */
export const Insert = ({
  label,
  projectInfo,
  waypoints,
  priceDetails,
  callbackNomal = undefined,
  callbackError = undefined,
}: { label: string } & MainProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  // エラーチェック＆登録処理
  const handleClick = () => {
    /*
    const validationErrors = validationCheck(projectInfo, waypoints);

    if (!isEmptyObject(validationErrors)) {
      setValidationMsg(validationErrors);
      setShowModal(true); // エラーがある場合モーダル表示
      return;
    }
      */

    // 積卸地点を整理する
    // 入力されていないデータは削除
    const organizeWp = waypoints.filter(
      (waypoint) =>
        waypoint.a_name !== '' ||
        waypoint.prefectures !== '' ||
        waypoint.city !== '' ||
        waypoint.abbreviation1 !== '' ||
        waypoint.abbreviation2 !== ''
    );

    if (organizeWp.length === 1) {
      if (organizeWp[0].kbn === waypointKbnLoad.id) {
        organizeWp.push({ ...initialWaypoint, kbn: waypointKbnUnload.id });
      } else {
        organizeWp.unshift({ ...initialWaypoint, kbn: waypointKbnLoad.id });
      }
    } else if (organizeWp.length === 0) {
      organizeWp.push({ ...initialWaypoint, kbn: waypointKbnUnload.id });
      organizeWp.unshift({ ...initialWaypoint, kbn: waypointKbnLoad.id });
    }

    // エラーがない場合、積卸地の日時を設定して登録処理
    projectInfo.load_datetime = organizeWp[0].datetime;
    projectInfo.unload_datetime = organizeWp[organizeWp.length - 1].datetime;
    projectInfo.waypoints = organizeWp;
    projectInfo.price_details = priceDetails;

    storeProject(projectInfo)
      .then((res) => {
        if (res.status !== 200) throw new Error('登録に失敗しました');
        alert('登録しました');
        callbackNomal && callbackNomal(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示

        // 最初が積地でなければ追加
        if (organizeWp[0].kbn !== waypointKbnLoad.id) {
          organizeWp.unshift({ ...initialWaypoint, kbn: waypointKbnLoad.id });
        }

        // 最後が卸地でなければ追加
        if (organizeWp[organizeWp.length - 1].kbn !== waypointKbnUnload.id) {
          organizeWp.push({ ...initialWaypoint, kbn: waypointKbnUnload.id });
        }

        callbackError && callbackError(projectInfo);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton onClick={handleClick} label={label} />
    </>
  );
};

/**
 * 更新処理
 * @returns
 */
export const Update = ({
  projectInfo,
  waypoints,
  priceDetails,
  callbackNomal = undefined,
  callbackError = undefined,
}: MainProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  // エラーチェック＆登録処理
  const handleClick = () => {
    projectInfo.waypoints = waypoints;
    projectInfo.price_details = priceDetails;

    // 更新処理
    updateProject(projectInfo)
      .then((res) => {
        if (res.status !== 200) throw new Error('更新に失敗しました');
        alert('更新しました');
        if (callbackNomal) callbackNomal(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (callbackError) callbackError(projectInfo);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton onClick={handleClick} label="更新" />
    </>
  );
};

/**
 * 削除処理
 * @returns
 */
export const Delete = ({
  pjId,
  callbackNomal,
  callbackError,
}: {
  pjId: tProject['id'];
  callbackNomal?: () => void;
  callbackError?: () => void;
}) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  // エラーチェック＆登録処理
  const handleClick = () => {
    if (!pjId) return;
    if (!window.confirm('削除しますか？')) return;
    deleteProject(pjId)
      .then((res: any) => {
        if (res.status !== 200) throw new Error('データ削除に失敗しました');
        alert('データを削除しました');
        if (callbackNomal) callbackNomal();
      })
      .catch((err) => {
        console.error(err);
        alert('データの削除に失敗しました。');
        if (callbackError) callbackError();
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <DeleteButton onClick={handleClick} label="削除" />
    </>
  );
};

export const Detail = ({
  pj_id,
  initValues = initProject,
  label = '詳細',
  callbackOpen = undefined,
  callbackClose = undefined,
}: {
  pj_id: number;
  initValues?: tProject;
  label?: string;
  callbackOpen?: () => void;
  callbackClose?: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleSuccess = () => {
    handleModalClose();
  };

  const handleModalOpen = () => {
    setOpen(true);
    if (callbackOpen) {
      callbackOpen();
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    if (callbackClose) {
      callbackClose();
    }
  };

  return (
    <>
      <PJModal
        open={open}
        onClose={handleModalClose}
        pjId={pj_id}
        initCustom={initValues}
        callbackNomal={() => handleSuccess}
      />
      <SecondButton label={label} onClick={handleModalOpen} />
    </>
  );
};

interface ShowSearchModalProps {
  values: tProjectSearch;
  setValues: React.Dispatch<React.SetStateAction<tProjectSearch>>;
  callbackSuccess?: (props: SearchModalCallbackProps) => void;
}
export const ShowSearchModal = ({
  values,
  setValues,
  callbackSuccess = undefined,
}: ShowSearchModalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SearchModal
        terms={values}
        setTerms={setValues}
        open={open}
        setOpen={setOpen}
        callback={(props: SearchModalCallbackProps) => {
          if (callbackSuccess) {
            callbackSuccess(props);
          }
        }}
      />
      <PrimaryButton label="検索" onClick={() => setOpen(true)} />
    </>
  );
};
