import {
  GridItemCheckbox,
  GridItemRowTextField,
  GridItemSelect,
  GridItemTextField,
} from 'components/GridItem';
import { days as closingDays } from 'const/comapny/closingDate';
import { closingDateType } from 'const/comapny/closingDateType';
import { handleChangeValues } from 'functions/handles';
import React from 'react';
import { tCompany } from 'types/company';

interface GridItemFieldProps {
  values: tCompany;
  setValues: React.Dispatch<React.SetStateAction<tCompany>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="会社名"
    name="name"
    value={values.name}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val) => handleChangeValues(val, 'name', setValues)}
  />
);

export const Abbreviation = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="略称"
    name="abbreviation"
    value={values.abbreviation}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'abbreviation', setValues)
    }
  />
);
export const AbbreviationKana = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="略称カナ"
    name="abbreviation_kana"
    value={values.abbreviation_kana}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'abbreviation_kana', setValues)
    }
  />
);

export const Tell = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="電話番号"
    name="tell"
    value={values.tell}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'tell', setValues)
    }
  />
);

export const Fax = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="FAX"
    name="fax"
    value={values.fax}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'fax', setValues)
    }
  />
);

export const EMail = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="メールアドレス"
    name="e_mail"
    value={values.e_mail}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'e_mail', setValues)
    }
  />
);

export const PresidentPosition = ({
  values,
  setValues,
}: GridItemFieldProps) => (
  <GridItemTextField
    label="代表役職"
    name="president_position"
    value={values.president_position}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'president_position', setValues)
    }
  />
);

export const PresidentName = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="代表者名"
    name="president_name"
    value={values.president_name}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'president_name', setValues)
    }
  />
);

export const FlgUse = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    label="傭者利用"
    name="flg_use"
    value={values.flg_use}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: boolean) =>
      handleChangeValues(val, 'flg_use', setValues)
    }
  />
);

export const FlgOrder = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    label="受注元"
    name="flg_order"
    value={values.flg_order}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: boolean) =>
      handleChangeValues(val, 'flg_order', setValues)
    }
  />
);

export const FlgSupplier = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    label="発注先"
    name="flg_supplier"
    value={values.flg_supplier}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: boolean) =>
      handleChangeValues(val, 'flg_supplier', setValues)
    }
  />
);

export const ClosingDate = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemSelect
    name={`closing_date`}
    label="締め日"
    value={values.closing_date}
    items={closingDays()}
    cbValueChange={(val) => {
      if (!val) return;
      setValues((prev) => ({
        ...prev,
        closing_date: val,
      }));
    }}
  />
);

export const ClosingDateType = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemSelect
    name={`closing_date_type`}
    label="基準日"
    value={values.closing_date_type}
    items={closingDateType}
    cbValueChange={(val) => {
      if (!val) return;
      setValues((prev) => ({
        ...prev,
        closing_date_type: val,
      }));
    }}
  />
);

export const FlgBillingPerson = ({ values, setValues }: GridItemFieldProps) => {
  return null;
  <GridItemCheckbox
    label="担当者別に請求書を発行"
    name="flg_billing_person"
    value={values.flg_billing_person}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: boolean) =>
      handleChangeValues(val, 'flg_billing_person', setValues)
    }
  />;
};

export const InvoiceNo = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="インボイス番号"
    name="invoice_no"
    value={values.invoice_no}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'invoice_no', setValues)
    }
  />
);

export const Address1 = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="住所"
    name="address1"
    value={values.address1}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'address1', setValues)
    }
  />
);

export const Address2 = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="建物名など"
    name="address2"
    value={values.address2}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'address2', setValues)
    }
  />
);

export const PostNumber = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="郵便番号"
    name="post_number"
    value={values.post_number}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'post_number', setValues)
    }
  />
);

export const InternalMemo = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemRowTextField
    label="社内メモ"
    name="internal_memo"
    value={values.internal_memo}
    size={{ xs: 12, lg: 6 }}
    row={5}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'internal_memo', setValues)
    }
  />
);
