import Loading from 'atoms/Loading';
import * as SelectItems from 'atoms/Select';
import { initAddress } from 'const/address';
import { NoSelect, prefectures, waypointKbn } from 'const/index';
import {
  projectEditStatus,
  projectStatus,
  projectStatusOrdered,
} from 'const/project/index';
import { MstDataContext } from 'contexts/Mst';
import log from 'functions/logger';
import { getTargetAddress } from 'functions/mst/address';
import React, { useContext, useEffect, useState } from 'react';
import { ItemSelectProps, List, ListItem } from 'types';
import { tAddress } from 'types/address';
import { tCompany } from 'types/company';
import { tRole, tTransportMethod, tVehicleType } from 'types/mst';

export const Addresses = React.memo(
  ({
    name = 'address_id',
    label = '住所',
    value,
    cbValueChange,
    flg_office = false,
    flg_load = false,
    flg_unload = false,
  }: ItemSelectProps & {
    flg_office?: boolean;
    flg_load?: boolean;
    flg_unload?: boolean;
  }) => {
    const { loading, addresses } = useContext(MstDataContext);
    const [options, setOptions] = React.useState<ListItem[]>([]);

    useEffect(() => {
      if (value) {
        const temp = addresses?.find(
          (address: tAddress) => address.id === value
        );
        if (temp) {
          cbValueChange(temp.id || null);
        } else {
          cbValueChange(initAddress.id || null);
        }
      } else {
        cbValueChange(initAddress.id || null);
      }
    }, [value]);

    useEffect(() => {
      if (addresses) {
        const ad = getTargetAddress(
          addresses,
          flg_office,
          flg_load,
          flg_unload
        );

        if (ad) {
          setOptions([
            NoSelect,
            ...ad.map((address: tAddress) => {
              return {
                id: address.id || null,
                label: address.name,
              };
            }),
          ]);
        } else {
          setOptions([{ id: 0, label: '' }]);
        }
      }
    }, [addresses, flg_office, flg_load, flg_unload]);

    return (
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={value}
        items={options || []}
        cbValueChange={cbValueChange}
      />
    );
  }
);

export const Driver = React.memo(
  ({
    name = 'user_id',
    label = 'ドライバー',
    value,
    cbValueChange,
  }: ItemSelectProps) => {
    const { loading, drivers } = useContext(MstDataContext);

    if (loading) {
      return <Loading flg={true} />;
    }

    return (
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={value}
        items={drivers || []}
        cbValueChange={cbValueChange}
      />
    );
  }
);

export const DriverHead = React.memo(
  ({
    name = 'v_id',
    label = 'ドライバー',
    value,
    cbValueChange,
  }: ItemSelectProps) => {
    const { loading, drivers } = useContext(MstDataContext);

    if (loading) {
      return <Loading flg={true} />;
    }

    return (
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={value}
        items={drivers || []}
        cbValueChange={cbValueChange}
      />
    );
  }
);

/**
 * 自社担当者
 * @param param0
 * @returns
 */
export const InCharge = ({
  name,
  label = '自社担当者',
  value,
  cbValueChange,
}: ItemSelectProps) => {
  const { users, roles, loading } = useContext(MstDataContext);
  const [operaters, setOperaters] = useState<List>([]);
  const [projectRoles, setProjectRoles] = useState<tRole[]>([]);

  useEffect(() => {
    if (!roles) {
      return;
    }

    // 案件入力を許可しているロールを取得
    const roleProject = roles.filter((role) => role.project === true);
    setProjectRoles(roleProject);
  }, [roles]);

  useEffect(() => {
    if (!users) {
      return;
    }

    // user.role_idがprojectRolesに含まれているユーザーを取得
    const roleUser = users.filter((user) => {
      if (!user.role_id) return [];
      return projectRoles.map((role) => role.id).includes(user.role_id);
    });
    const temp = roleUser.map((user) => ({
      id: user.id || null,
      label: `${user.family_name} ${user.given_name}`,
    }));
    setOperaters(temp);
  }, [users, projectRoles]);

  if (loading) {
    return <Loading flg={true} />;
  }

  return (
    <SelectItems.SmallSelect
      name={name}
      label={label}
      value={value}
      items={operaters}
      cbValueChange={cbValueChange}
    />
  );
};

/**
 * 荷物種類
 * @param param0
 * @returns
 */
export const LuggageID = ({
  name = 'l_id',
  label = '荷物種類',
  value,
  cbValueChange,
}: ItemSelectProps) => {
  const { loading, luggages } = useContext(MstDataContext);
  if (loading) {
    return <Loading flg={true} />;
  }

  const options = luggages?.map((luggage) => ({
    id: luggage.id,
    label: luggage.name,
  })) as List;

  return (
    <SelectItems.SmallSelect
      name={name}
      label={label}
      value={value}
      items={options}
      cbValueChange={cbValueChange}
    />
  );
};

/**
 * 案件区分
 * @param param0
 * @returns
 */
export const ProjectKind = ({
  name = 'kind',
  label = '案件区分',
  value,
  cbValueChange,
  flgNoSelect = false,
}: ItemSelectProps) => {
  const { loading, projectKinds } = useContext(MstDataContext);

  if (loading || !projectKinds) return <Loading flg={true} />;

  const options = projectKinds.map((kind) => ({
    id: kind.id,
    label: kind.name,
  })) as List;

  if (flgNoSelect) {
    options.unshift({ id: 0, label: 'その他' });
  }

  log.debug('ProjectKind', value);

  return (
    <SelectItems.SmallSelect
      name={name}
      label={label}
      value={value}
      items={options}
      cbValueChange={cbValueChange}
    />
  );
};

/**
 * 案件ステータス
 * @param param0
 * @returns
 */
export const PJStatus = ({
  name = 'pj-status',
  label = '案件ステータス',
  value,
  cbValueChange,
  disabled = false,
}: ItemSelectProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  if (!SYSTEM) return <Loading flg={true} />;

  let list = [];
  if (value || 0 >= projectStatusOrdered.id) {
    list = projectStatus;
  } else {
    list = projectEditStatus;
  }

  return (
    <SelectItems.SmallSelect
      name={name}
      label={label}
      value={value}
      items={list}
      cbValueChange={cbValueChange}
      disabled={disabled}
    />
  );
};

export const PriceDetailKbn = React.memo(
  ({ name = 'kbn', label = '区分', value, cbValueChange }: ItemSelectProps) => {
    const { SYSTEM } = useContext(MstDataContext);

    if (!SYSTEM) return <Loading flg={true} />;

    const options = SYSTEM.project.price_detail.list.map((kbn) => ({
      id: kbn.id,
      label: kbn.label,
    })) as List;

    return (
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={value}
        items={options}
        cbValueChange={cbValueChange}
      />
    );
  }
);

export const Role = React.memo(
  ({
    name = 'role_id',
    label = 'ロール',
    value,
    cbValueChange,
  }: ItemSelectProps) => {
    const { loading, roles } = useContext(MstDataContext);

    if (loading) {
      return <Loading flg={true} />;
    }

    const options = roles?.map((role) => ({
      id: role.id,
      label: role.name,
    })) as List;

    return (
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={value}
        items={options}
        cbValueChange={cbValueChange}
      />
    );
  }
);

export interface PrefecturesProps {
  label?: string;
  name?: string;
  value: string | null;
  cbValueChange: (e: string) => void /** labelを変えるため */;
  flgNoSelect?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
}

export const Prefectures = React.memo(
  ({
    name = 'prefectures',
    label = '都道府県',
    value,
    cbValueChange,
  }: PrefecturesProps) => {
    const obj = prefectures.find((obj) => obj.label === value);

    return (
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={obj?.id || 0}
        items={prefectures}
        cbValueChange={(val) => {
          const obj = prefectures.find((obj) => obj.id === val);
          cbValueChange(obj?.label || '');
        }}
      />
    );
  }
);

/**
 * 税率
 * @param param0
 * @returns
 */
export const Tax = ({
  label = '税率',
  name = 'tax_id',
  value,
  flgNoSelect,
  cbValueChange,
}: ItemSelectProps) => {
  const { loading, SYSTEM } = useContext(MstDataContext);

  if (loading || !SYSTEM?.tax) {
    return <Loading flg={true} />;
  }

  const options = SYSTEM?.tax.list.map((tax) => ({
    id: tax.id,
    label: tax.label,
  })) as List;

  return (
    <SelectItems.SmallSelect
      name={name}
      label={label}
      value={value}
      items={options}
      flgNoSelect={flgNoSelect}
      cbValueChange={cbValueChange}
    />
  );
};

/**
 * 自社シャーシ
 * @param param0
 * @returns
 */
export const Trailer = ({
  name = 'trailer_v_id',
  label = 'シャーシ',
  value,
  cbValueChange,
}: ItemSelectProps) => {
  const { loading, trailer } = useContext(MstDataContext);

  const selected = trailer?.find((obj) => obj.id === value);

  const [options, setOptions] = React.useState<List>([]);

  useEffect(() => {
    if (trailer) {
      const trailers = trailer.map((obj) => ({
        id: obj.id || null,
        label: obj.plate,
      }));
      setOptions([NoSelect, ...trailers]);
    }
  }, [trailer]);

  //log.debug("selectedDriver", selectedDriver);

  if (loading || !trailer) {
    return <Loading flg={true} />;
  }

  return (
    <SelectItems.SmallSelect
      name={name}
      label={label}
      value={selected ? Number(selected.id) : 0}
      items={options}
      cbValueChange={cbValueChange}
    />
  );
};

export const TransportMethoed = React.memo(
  ({
    name = 'tm_id',
    label = '輸送方法',
    value,
    cbValueChange,
  }: ItemSelectProps) => {
    const { loading, tranMethods } = useContext(MstDataContext);

    const options = tranMethods?.map((method: tTransportMethod) => ({
      id: method.id || null,
      label: method.name,
    })) as List;

    if (loading || !tranMethods) {
      return <Loading flg={true} />;
    }

    //log.debug('values.tm_id', values.tm_id);

    return (
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={value}
        items={options}
        cbValueChange={cbValueChange}
      />
    );
  }
);

/**
 * 車種タイプ
 * @param param0
 * @returns
 */
export const VehicleType = ({
  name = 'vt_id',
  label = '車種タイプ',
  value,
  cbValueChange,
  flgVehicle = true,
  flgTrailer = true,
}: ItemSelectProps & { flgVehicle?: boolean; flgTrailer?: boolean }) => {
  const { loading, vehicleTypes } = useContext(MstDataContext);
  if (loading || !vehicleTypes) {
    return <Loading flg={true} />;
  }
  console.log('vehicleTypes', vehicleTypes);

  let targetVTypes: tVehicleType[] = [];

  if (flgVehicle === true) {
    if (flgTrailer === true) {
      // flg_trailerがtrueの車両種類のみを抽出
      targetVTypes = vehicleTypes;
    } else {
      // flg_trailerがfalseの車両種類のみを抽出
      targetVTypes =
        vehicleTypes.filter(
          (vehicleType) => vehicleType.flg_trailer === false
        ) || [];
    }
  } else {
    if (flgTrailer === true) {
      // flg_trailerがtrueの車両種類のみを抽出
      targetVTypes =
        vehicleTypes.filter(
          (vehicleType) => vehicleType.flg_trailer === true
        ) || [];
    } else {
      //targetVType = [];
    }
  }

  const options = targetVTypes.map((vehicleType) => ({
    id: vehicleType.id,
    label: vehicleType.name,
  })) as List;

  options.push(NoSelect);

  return (
    <SelectItems.SmallSelect
      name={name}
      label={label}
      value={value}
      items={options}
      cbValueChange={cbValueChange}
    />
  );
};

export const WaypointKbn = React.memo(
  ({
    name = 'waypoint_kbn',
    label = '経由地区分',
    value,
    cbValueChange,
    disabled = false,
  }: ItemSelectProps) => {
    return (
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={value}
        items={waypointKbn}
        cbValueChange={cbValueChange}
        disabled={disabled}
      />
    );
  }
);

export const TradeType = React.memo(
  ({
    name = 'trade_type',
    label = '取引区分',
    value,
    c_id,
    cbValueChange,
    disabled = false,
  }: ItemSelectProps & { c_id: tCompany['id'] }) => {
    const { companies } = useContext(MstDataContext);
    const [tradeTypes, setTradeTypes] = useState<List>([]);

    if (!companies) return <Loading flg={true} />;

    useEffect(() => {
      const company = companies.find((company) => company.id === c_id);
      const ttList =
        (company?.trade_types?.map((tradeType) => ({
          id: tradeType.id,
          label: tradeType.name,
        })) as List) || [];

      setTradeTypes(ttList);

      // valueの存在チェック

      const target = ttList.find((obj) => obj.id === value);
      if (!target) {
        cbValueChange(null);
      } else {
        cbValueChange(target.id);
      }
    }, [c_id, companies]);

    return (
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={Number(value)}
        items={tradeTypes}
        cbValueChange={cbValueChange}
        disabled={!c_id || disabled || tradeTypes.length === 0 ? true : false}
      />
    );
  }
);
