import { Button, Grid, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import AutocompleteSelect from 'atoms/AutoComplete';
import { SecondButton } from 'atoms/Button';
import { CheckBox } from 'atoms/Checkbox';
import Loading from 'atoms/Loading';
import Modal from 'atoms/Modal';
import { GridItemTextFieldCustom } from 'components/GridItem';
import * as InstCard from 'components/instruction/InstructionCard';
import InstructionModal from 'components/instruction/Modal';
import * as TranCard from 'components/transport/Card';
import initInstruction from 'const/instruction';
import initInstSearch from 'const/instruction/search';
import { MstDataContext } from 'contexts/Mst';
import { useSelection } from 'contexts/Schedules';
import TableFrame from 'frames/TableFrame';
import * as apiInst from 'functions/api/instruction';
import { handleChangeValues } from 'functions/handles';
import log from 'functions/logger';
import {
  changeDateToStrDay,
  convertLocalDateToUTC,
  isSameDate,
  mathDateTime,
} from 'functions/time';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { apiGetListSuccess, ListItem } from 'types/index';
import {
  cbGetInstructionCardList,
  tInstCardSearch,
  tInstruction,
  tInstructionCard,
  tInstSearch,
} from 'types/instruction';
import { tTransport, tTransportCard, tTransportKey } from 'types/transport';

const ddType = 'TransportCard';

type tCustomInstCard = tInstructionCard & {
  v_tran_card: tTransportCard;
};

type tranCardDropProps = (item: tInstruction) => void;
type driverBlankClickProps = ({
  driver,
  key,
}: {
  driver: ListItem['id'];
  key: string;
}) => void;
type instCardClickProps = ({
  pj_id,
  tran_id,
  no,
  idx,
}: tTransportKey & { tran_id: tTransport['id']; idx: number }) => void;
type tTableRowData = {
  driver: ListItem['id'];
  data: tCustomInstCard[];
  dataBefore1: tCustomInstCard[];
  dataBefore2: tCustomInstCard[];
  dataBefore3: tCustomInstCard[];
  dataAfter: tCustomInstCard[];
  key: string;
};
type tFlgOmits = {
  before3: boolean;
  before2: boolean;
  before1: boolean;
  now: boolean;
  after1: boolean;
};
const initTableRowData: tTableRowData = {
  driver: 0,
  data: [],
  dataBefore1: [],
  dataBefore2: [],
  dataBefore3: [],
  dataAfter: [],
  key: '',
};

export default function Main() {
  const navigate = useNavigate();
  const { drivers, SYSTEM, loading, loginUser, users } =
    useContext(MstDataContext);
  const { transportCards } = useSelection();

  const [targetDriver, setTargetDriver] = useState<ListItem[]>([]);
  const [selectableDriver, setSelectableDriver] = useState<ListItem[]>([]);
  const [tableRowData, setTableRowData] = useState<tTableRowData[]>([]);

  const [trigger, setTrigger] = useState('');
  const [searchInst, setSearchInst] = useState<tInstSearch>(initInstSearch);
  const [date, setDate] = useState<Date>(
    mathDateTime(new Date(), [0, 0, 2, 0, 0, 0])
  );
  const [flgOmits, setFlgOmits] = useState<tFlgOmits>({
    before3: false,
    before2: false,
    before1: false,
    now: false,
    after1: false,
  });

  const [openInstModal, setOpenInstModal] = useState(false);
  const [dropInst, setDropInst] = useState<tInstruction>(initInstruction);

  const [openDriverModal, setDriverModalOpen] = useState(true);

  useEffect(() => {
    // データの再取得
    getDataList(date);
  }, [targetDriver]);

  useEffect(() => {
    // モーダル表示初期値を更新
    setDropInst((prev) => {
      return {
        ...prev,
        start_datetime: changeDateToStrDay(date),
        end_datetime: changeDateToStrDay(date),
      };
    });

    // データの再取得
    getDataList(date);
  }, [date]);

  useEffect(() => {
    if (!drivers) {
      return;
    }
    // tableRowDataのdriverを配列にする
    const selectDriverList = tableRowData.map((rowData) => rowData.driver);
    // driversからtableRowDataにないdriverを取得
    const newDriver = targetDriver.filter(
      (driver) => !selectDriverList.includes(driver.id)
    );
    //log.debug('newDriver', newDriver);
    setSelectableDriver(newDriver);
  }, [tableRowData]);

  /**
   * 対象日の運行指示を取得
   * calbackが指定されていない場合はデータをマージする
   * @param date
   */
  const getDataList = (
    date: Date,
    callback:
      | (({ data, filter }: apiGetListSuccess) => void)
      | undefined = undefined
  ) => {
    //log.debug('getDataList', date);

    const driverIds =
      targetDriver.length > 0
        ? [
            null,
            ...targetDriver
              .map((driver) => driver.id)
              .filter((driver): driver is number => driver !== undefined),
          ]
        : null;

    const filter: tInstCardSearch = {
      key: null,
      pj_id: null,
      no: null,
      order: null,
      start_datetime_from: null,
      start_datetime_to: null,
      end_datetime_from: mathDateTime(
        convertLocalDateToUTC(date.toISOString()),
        [0, 0, -3, 0, 0, 0]
      ).toISOString(),
      end_datetime_to: mathDateTime(
        convertLocalDateToUTC(date.toISOString()),
        [0, 0, 1, 0, 0, 0]
      ).toISOString(),
      tm_id: SYSTEM?.tranMethod.own.id || 1,
      status: null,
      driver_id: driverIds,
      v_id: null,
      c_id: null,
      update_user_id: [loginUser.id],
    };

    // コールバックがない場合はデフォルトのマージ処理を行う
    if (!callback) {
      callback = ({ data, filter }: apiGetListSuccess) => {
        const rows: tTableRowData[] = targetDriver.map(
          (driver, index: number) => {
            return {
              ...initTableRowData,
              key: `${date.toISOString()}-${index}`,
            };
          }
        );

        // 既存データをマージ
        setTableRowDataFromData({
          date: date,
          data: data,
          drivers: drivers || [],
          tableRowData: rows,
          setTableRowData: setTableRowData,
        });
      };
    }

    // ドライバーが選択されていない場合は処理を抜ける
    if (!targetDriver || targetDriver.length <= 0) {
      callback({ data: [], filter: filter });
      return;
    }

    // 検索処理
    apiInst.getInstructionCardList({
      filter: filter,
      withs: ['vTranCard'],
      callbackSuccess: ({ data, filter }: apiGetListSuccess) => {
        if (callback) callback({ data, filter });
      },
    });
  };

  /**
   * 輸送情報カードをドロップした時の処理
   * @param param0
   */
  const handleDrop = (item: tInstruction) => {
    log.debug('handleDrop', item);
    setDropInst((prev) => {
      return {
        ...prev,
        ...item,
        tran_id: item.tran_id,
        start_datetime: prev.start_datetime || changeDateToStrDay(date),
        end_datetime: prev.end_datetime || changeDateToStrDay(date),
      };
    });
    setOpenInstModal(true);
  };

  /**
   * 運行指示カードクリック時の処理
   * @param param0
   */
  const handleInstCardClick: instCardClickProps = ({
    pj_id: pjId,
    no: tranNo,
    tran_id,
    idx,
  }: tTransportKey & { tran_id: tTransport['id']; idx: number }) => {
    // ドライバーのデフォルト車両IDを取得
    const driverInfo = users?.find(
      (user) => user.id === tableRowData[idx].driver
    );

    // 運行指示入力モーダルを表示
    //log.debug('instCardClick', pjId, tranNo);
    setDropInst({
      ...initInstruction,
      pj_id: pjId,
      tran_id: tran_id,
      no: tranNo,
      user_id: tableRowData[idx].driver,
      v_id: driverInfo?.v_id,
    });
    setOpenInstModal(true);
  };

  /**
   * Blankカードクリック時の処理
   * @param param0
   * @returns
   */
  const handleBlankClick = useCallback(
    ({ driver, key }: { driver: ListItem['id']; key: string }) => {
      // 運行指示入力モーダルを表示
      //log.debug('blankClick', driver);
      if (!SYSTEM) return;

      const driverInfo = users?.find((user) => user.id === driver);

      //alert(driverInfo?.id);

      log.debug('blankClick', driverInfo);
      setDropInst({
        ...initInstruction,
        key: key,
        tm_id: SYSTEM.tranMethod.own.id || undefined,
        user_id: driver,
        v_id: driverInfo?.v_id || 0,
        start_datetime: changeDateToStrDay(date),
        end_datetime: changeDateToStrDay(date),
      });
      setOpenInstModal(true);
    },
    [SYSTEM, users]
  );

  /**
   * モーダルでデータ登録後の処理
   * ここでtableRowDataにデータを追加する
   * @param data
   */
  const callbackInserts = (data: tInstruction[]) => {
    log.debug('callbackInserts', data);
  };

  const closeInstModal = () => {
    log.debug('closeInstModal', date);
    setOpenInstModal(false);
    //setTrigger(new Date().getTime().toString());
    const callback = ({ data, filter }: apiGetListSuccess) => {
      setTableRowDataFromData({
        date: date,
        data: data,
        drivers: drivers || [],
        tableRowData: tableRowData,
        setTableRowData: setTableRowData,
      });
    };

    getDataList(date, callback);
  };

  useEffect(() => {
    if (!openInstModal) {
      setDropInst(initInstruction);
    }
  }, [openInstModal]);

  const callbackGetList = ({ data, filter }: cbGetInstructionCardList) => {
    //setInstructions(data);
  };

  useEffect(() => {
    getDataList(date);
  }, [trigger, searchInst]);

  return (
    <DndProvider backend={HTML5Backend}>
      <TableFrame
        HeadContent={
          <HeadContent
            date={date}
            setDate={setDate}
            setSearchInst={setSearchInst}
            trigger={trigger}
            setDriverModalOpen={setDriverModalOpen}
          />
        }
        TableHeaderRows={
          <HeadData date={date} flgOmits={flgOmits} setFlgOmits={setFlgOmits} />
        }
        TableBodyRows={
          <BodyData
            drivers={selectableDriver}
            data={tableRowData}
            flgOmits={flgOmits}
            setData={setTableRowData}
            setTrigger={setTrigger}
            handleDrop={handleDrop}
            handleInstCardClick={handleInstCardClick}
            handleBlankClick={handleBlankClick}
          />
        }
        SubContent={
          <TranCard.DragTransportCardArea
            ddType={ddType}
            transportCards={transportCards}
            optionNode={
              <Button
                variant="contained"
                onClick={() => {
                  navigate('/full-screen/schedules');
                }}
              >
                予定表へ
              </Button>
            }
          />
        }
      />

      <ModalDriver
        stateOpen={[openDriverModal, setDriverModalOpen]}
        stateDrivers={[targetDriver, setTargetDriver]}
      />

      <InstructionModal
        open={openInstModal}
        onClose={closeInstModal}
        tranKey={{ pj_id: dropInst?.pj_id || 0, no: dropInst?.no || 0 }}
        info={dropInst}
        callbackNomal={callbackInserts}
      />
    </DndProvider>
  );
}

interface HeadContentProps {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  setSearchInst: React.Dispatch<React.SetStateAction<tInstSearch>>;
  trigger: string;
  setDriverModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const HeadContent = ({
  date,
  setDate,
  setSearchInst,
  trigger,
  setDriverModalOpen,
}: HeadContentProps) => {
  const theme = useTheme();
  log.debug('HeadContent', date.toDateString());
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <SecondButton
          onClick={() => {
            log.debug('before', date);
            setDate(mathDateTime(date, [0, 0, -1, 0, 0, 0]));
          }}
          label={'<'}
        />
        <Typography variant="h6">{changeDateToStrDay(date)}</Typography>
        <SecondButton
          onClick={() => setDate(mathDateTime(date, [0, 0, 1, 0, 0, 0]))}
          label={'>'}
        />
      </Box>
      <SecondButton
        onClick={() => setDriverModalOpen(true)}
        label={'ドライバー選択'}
      />
    </>
  );
};

interface HeadDataProps {
  date: Date;
  flgOmits: tFlgOmits;
  setFlgOmits: React.Dispatch<React.SetStateAction<tFlgOmits>>;
}
const HeadData = ({ date, flgOmits, setFlgOmits }: HeadDataProps) => {
  return (
    <TableRow>
      <TableCell className="driver" sx={{ minWidth: '200px' }}>
        ドライバー
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        <CheckBox
          name={'before3'}
          label={changeDateToStrDay(mathDateTime(date, [0, 0, -3, 0, 0, 0]))}
          checked={!flgOmits.before3}
          onChange={(e: any) =>
            handleChangeValues(!e.target.checked, 'before3', setFlgOmits)
          }
          checkboxProps={{ className: 'color-second' }}
        />
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        <CheckBox
          name={'before2'}
          label={changeDateToStrDay(mathDateTime(date, [0, 0, -2, 0, 0, 0]))}
          checked={!flgOmits.before2}
          onChange={(e: any) =>
            handleChangeValues(!e.target.checked, 'before2', setFlgOmits)
          }
          checkboxProps={{ className: 'color-second' }}
        />
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        <CheckBox
          name={'before1'}
          label={changeDateToStrDay(mathDateTime(date, [0, 0, -1, 0, 0, 0]))}
          checked={!flgOmits.before1}
          onChange={(e: any) =>
            handleChangeValues(!e.target.checked, 'before1', setFlgOmits)
          }
          checkboxProps={{ className: 'color-second' }}
        />
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        {changeDateToStrDay(date)}
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        <CheckBox
          name={'after1'}
          label={changeDateToStrDay(mathDateTime(date, [0, 0, 1, 0, 0, 0]))}
          checked={!flgOmits.after1}
          onChange={(e: any) =>
            handleChangeValues(!e.target.checked, 'after1', setFlgOmits)
          }
          checkboxProps={{ className: 'color-second' }}
        />
      </TableCell>
    </TableRow>
  );
};

interface BodyDataProps {
  drivers: ListItem[];
  data: tTableRowData[];
  flgOmits: tFlgOmits;
  setData: React.Dispatch<React.SetStateAction<tTableRowData[]>>;
  setTrigger: React.Dispatch<React.SetStateAction<string>>;
  handleDrop: tranCardDropProps;
  handleInstCardClick: instCardClickProps;
  handleBlankClick?: driverBlankClickProps;
}
const BodyData = ({
  drivers,
  data,
  flgOmits,
  setData,
  setTrigger,
  handleDrop,
  handleInstCardClick,
  handleBlankClick = undefined,
}: BodyDataProps) => {
  const { users } = useContext(MstDataContext);
  /**
   * ドライバー変更時のコールバック
   * @param param0
   */
  const callbackRowDirver = ({
    driver,
    idx,
  }: {
    driver: ListItem['id'];
    idx: number;
  }) => {
    // 運行指示があるなら更新処理を行う
    if (data[idx].data && data[idx].data.length > 0) {
      // 運行指示のドライバーIDを変更
      try {
        // nullを除く運行指示idを配列にする
        const ids: number[] = data[idx].data
          .map((inst: tInstructionCard) => inst.id)
          .filter((id): id is number => id !== null);

        // ドライバーに既定車両があるならセット
        const driverInfo = users?.find((item) => item.id === driver);
        let updateItems = {};
        if (driverInfo) {
          // ドライバーがいるならその情報を
          updateItems = {
            user_id: driver,
            v_id: driverInfo?.v_id || null,
          };
        } else {
          // ドライバーがいない、またはドライバーを消した場合
          updateItems = { user_id: null, v_id: null };
        }

        const callbackSuccess = (data: tInstruction[]) => {
          log.debug('callbackSuccess', data);
        };

        const callbackError = (error: any) => {
          log.debug('callbackError');
          throw new Error('更新に失敗しました');
        };

        // 更新処理を実行
        apiInst.updateAry(ids, updateItems, callbackSuccess, callbackError);
      } catch (e: Error | any) {
        log.debug('error', e);
        alert('更新に失敗しました' + e.message);
      }
    }

    // 表示データの更新
    setData((prev) => {
      const newData = [...prev];
      newData[idx].driver = driver;

      if (!driver) {
        // ドライバーが消された場合、未来過去を消す
        newData[idx].dataAfter = [];
        newData[idx].dataBefore1 = [];
        newData[idx].dataBefore2 = [];
        newData[idx].dataBefore3 = [];
      }

      return newData;
    });
    setTrigger(new Date().toISOString());
    if (driver) {
      // ドライバーをつけた場合
    }
  };

  const CellInstData = ({
    rowKey,
    driver,
    data,
    rowIdx,
    flgBlank = false,
    flgOmit = false,
  }: {
    rowKey: tTableRowData['key'];
    driver: ListItem['id'];
    data: tCustomInstCard[];
    rowIdx: number;
    flgBlank?: boolean;
    flgOmit?: boolean;
  }) => {
    return (
      <Box
        sx={{
          //width: '100%',
          display: 'flex',
          flexBasis: 'auto',
          flexWrap: 'wrap nowrap',
        }}
      >
        {data.map((inst, idx) => {
          log.debug('CellInstData', inst);
          if (inst.v_tran_card) {
            return (
              <TranCard.Nomal
                key={rowKey + '-' + idx}
                transportCard={inst.v_tran_card}
                flgOmit={flgOmit}
                callbackClick={() => {
                  handleInstCardClick({
                    pj_id: inst.pj_id,
                    tran_id: inst.tran_id,
                    no: inst.no,
                    idx: rowIdx,
                  });
                }}
              />
            );
          } else {
            return (
              <InstCard.Nomal
                key={rowKey + '-' + idx}
                type={'box'}
                instruction={inst}
                flgOmit={flgOmit}
                callbackClick={() => {
                  handleInstCardClick({
                    pj_id: inst.pj_id,
                    tran_id: inst.tran_id,
                    no: inst.no,
                    idx: rowIdx,
                  });
                }}
              />
            );
          }
        })}
        {flgBlank && !flgOmit && (
          <TranCardBlank
            driver={driver}
            callbackDrop={(item: tInstruction) => {
              item.key = rowKey;
              handleDrop(item);
            }}
            callbackClick={() => {
              if (handleBlankClick)
                handleBlankClick({
                  driver: driver,
                  key: rowKey,
                });
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      {data.map((rowData: tTableRowData, idx: number) => {
        //log.debug('BodyData', rowData);
        return (
          <TableRow key={`instruction-scheduleEdit-row-${idx}`}>
            <TableCell variant="head">
              <RowDriver
                items={drivers}
                value={rowData.driver}
                callbackChange={({ driver }: { driver: ListItem['id'] }) => {
                  callbackRowDirver({ driver: driver, idx: idx });
                }}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.dataBefore3}
                flgOmit={flgOmits.before3}
                rowIdx={idx}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.dataBefore2}
                flgOmit={flgOmits.before2}
                rowIdx={idx}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.dataBefore1}
                flgOmit={flgOmits.before1}
                rowIdx={idx}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.data}
                flgBlank={true}
                rowIdx={idx}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.dataAfter}
                flgOmit={flgOmits.after1}
                rowIdx={idx}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

/**
 * 新規作成用カード
 * @param param0
 * @returns
 */
const TranCardBlank = ({
  driver,
  callbackDrop,
  callbackClick = undefined,
}: {
  driver: ListItem['id'];
  callbackDrop: tranCardDropProps;
  callbackClick?: (() => void) | undefined;
}) => {
  const { SYSTEM } = useContext(MstDataContext);

  const { isOver, dropRef } = useDropHandler(callbackDrop, {
    user_id: driver,
    tm_id: SYSTEM?.tranMethod.own.id || 1,
  });

  const Toolchip = () => {
    return (
      <Box>
        <Typography>ドラッグして運行指示を追加</Typography>
      </Box>
    );
  };

  return (
    <InstCard.Blank
      ref={dropRef}
      flgOmit={false}
      label="新"
      callbackDoubleClick={(event) => {
        if (callbackClick) {
          callbackClick();
        }
      }}
      isOver={isOver}
      //toolchipChildren={<Toolchip />}
    />
  );
};

interface RowDriverProps {
  value: tTableRowData['driver'];
  callbackChange: ({ driver }: { driver: ListItem['id'] }) => void;
  size?: Record<string, number>;
  items: ListItem[];
}
export const RowDriver = ({
  value,
  callbackChange,
  size = { xs: 12, lg: 3, xl: 3 },
  items,
}: RowDriverProps & { items: ListItem[] }) => {
  const { drivers } = useContext(MstDataContext);
  const selectedOption = drivers?.find((item) => item.id === value);

  return (
    <GridItemTextFieldCustom size={size}>
      <AutocompleteSelect
        disableClearable={false}
        name="user_id"
        label={''}
        items={items}
        value={{
          id: selectedOption?.id || null,
          label: selectedOption?.label || '',
        }}
        cbValueChange={(newValue: ListItem) => {
          if (!newValue) {
            callbackChange({ driver: 0 });
            return;
          }
          callbackChange({ driver: newValue.id });
        }}
      />
    </GridItemTextFieldCustom>
  );
};

/**
 * ドロップ処理のハンドラ
 * @param day
 * @param callbackDrop
 * @param extraData
 * @returns
 */
export const useDropHandler = (
  callbackDrop: (item: tInstruction) => void,
  extraData: Partial<tInstruction> = {}
) => {
  const extraDataRef = useRef(extraData);

  // extraData が変更された場合に ref を更新
  extraDataRef.current = extraData;

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: 'TransportCard', // ドラッグ対象の種類
      drop: (item: tTransportCard) => {
        // ドロップされた時の処理
        const defInst: tInstruction = {
          ...initInstruction,
          pj_id: item.pj_id,
          tran_id: item.id,
          no: item.tran_no,
          ...extraDataRef.current, // driver_id や tm_id を渡せるようにする
        };
        callbackDrop(defInst);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [callbackDrop, extraData]
  );

  return { isOver, dropRef };
};

/**
 * TableRowDataを全て更新する処理
 * 現在未使用
 * @param param0
 * @returns
 */
const changeTableRowDataFromData = ({
  date,
  data,
  drivers,
}: {
  date: Date;
  data: tCustomInstCard[];
  drivers: ListItem[];
}) => {
  //log.debug('changeTableRowDataFromData', data);
  const newTableRowData: tTableRowData[] = [];

  // dataの中からドライバーが設定されているものを抽出
  const driverData = data.filter(
    (inst) => inst.driver_id && isSameDate(inst.end_datetime, date)
  );

  // ドライバー単位でグループ化
  const driverGroupData = driverData.reduce(
    (acc, cur) => {
      (acc[cur.driver_id || 0] = acc[cur.driver_id || 0] || []).push(cur);
      return acc;
    },
    {} as Record<number, tCustomInstCard[]>
  );

  // グループ毎にnewTableRowDataにデータを追加
  Object.keys(driverGroupData).forEach((key) => {
    const driverId = Number(key); // 文字列を数値に変換

    // 卸日がdateのものを抽出
    const dateData = driverGroupData[driverId].filter((inst) =>
      isSameDate(inst.end_datetime, date.toLocaleDateString())
    );

    if (!dateData || dateData.length === 0) {
      return;
    }

    // 卸日がdate - 1のものを抽出
    const dateBefore1Data = driverGroupData[driverId].filter((inst) =>
      isSameDate(
        inst.end_datetime,
        mathDateTime(date, [0, 0, -1, 0, 0, 0]).toLocaleDateString()
      )
    );

    // 卸日がdate - 2のものを抽出
    const dateBefore2Data = driverGroupData[driverId].filter((inst) =>
      isSameDate(
        inst.end_datetime,
        mathDateTime(date, [0, 0, -2, 0, 0, 0]).toLocaleDateString()
      )
    );

    // 卸日がdate - 3のものを抽出
    const dateBefore3Data = driverGroupData[driverId].filter((inst) =>
      isSameDate(
        inst.end_datetime,
        mathDateTime(date, [0, 0, -3, 0, 0, 0]).toLocaleDateString()
      )
    );

    //  卸日がdate + 1のものを抽出
    const dateAfterData = driverGroupData[driverId].filter((inst) =>
      isSameDate(
        inst.end_datetime,
        mathDateTime(date, [0, 0, 1, 0, 0, 0]).toLocaleDateString()
      )
    );

    newTableRowData.push({
      driver: Number(key),
      data: dateData,
      dataBefore1: dateBefore1Data,
      dataBefore2: dateBefore2Data,
      dataBefore3: dateBefore3Data,
      dataAfter: dateAfterData,
      key: `${date.toISOString()}-${key}`,
    });
  });

  // dataの中からドライバーが設定されていないものを抽出
  const noDriverData = data.filter((inst) => !inst.driver_id);

  // keyでグループ化
  const groupData = noDriverData.reduce(
    (acc, cur) => {
      (acc[cur.key] = acc[cur.key] || []).push(cur);
      return acc;
    },
    {} as Record<string, tCustomInstCard[]>
  );

  // グループの並び順をkeyの昇順に変更
  const groupDataKeys = Object.keys(groupData).sort();
  const groupDataSorted = groupDataKeys.reduce(
    (acc, key) => {
      acc[key] = groupData[key];
      return acc;
    },
    {} as Record<string, tCustomInstCard[]>
  );

  // グループ毎にnewTableRowDataにデータを追加
  Object.keys(groupDataSorted).forEach((key) => {
    // 卸日がdateのものを抽出
    const dateData = groupDataSorted[key].filter((inst) =>
      isSameDate(inst.end_datetime, changeDateToStrDay(date))
    );

    if (!dateData || dateData.length === 0) {
      return;
    }

    newTableRowData.push({
      driver: 0,
      data: dateData,
      dataBefore1: [],
      dataBefore2: [],
      dataBefore3: [],
      dataAfter: [],
      key: key,
    });
  });

  return newTableRowData;
};

/**
 * tableRowDataにdataを設定する
 * @param param0
 */
const setTableRowDataFromData = ({
  date,
  data,
  drivers,
  tableRowData,
  setTableRowData,
}: {
  date: Date;
  data: tCustomInstCard[];
  drivers: ListItem[];
  tableRowData: tTableRowData[];
  setTableRowData: React.Dispatch<React.SetStateAction<tTableRowData[]>>;
}) => {
  const newTableRowData: tTableRowData[] = [];
  const newDriverData: tTableRowData[] = [];
  const newNoDriverData: tTableRowData[] = [];

  // tableRowDataの配列分だけループ
  tableRowData.forEach((rowData) => {
    let rowInst: tCustomInstCard[] = [];
    if (rowData.driver) {
      // ドライバーが設定されている場合、dataから同じドライバーのデータを抽出
      rowInst = data.filter((inst) => inst.driver_id === rowData.driver);
      log.debug('ドライバーが設定されている場合', rowData.driver);
    } else {
      // ドライバーが設定されていない場合、keyが一致するデータを抽出
      //log.debug('key', rowData.key);
      rowInst = data.filter((inst) => inst.key === rowData.key);
    }

    // dataからrowInstを削除
    data = data.filter((inst) => !rowInst.includes(inst));

    //log.debug('rowInst', rowInst);

    // dataの中から卸日がdateのものを抽出
    const [before3, brfore2, before1, nowDay, after] = dataSortSameDate(
      date,
      rowInst
    );

    // グループ毎にnewTableRowDataにデータを追加
    newTableRowData.push({
      driver: rowData.driver,
      data: nowDay,
      dataBefore1: before1,
      dataBefore2: brfore2,
      dataBefore3: before3,
      dataAfter: after,
      key: rowData.key,
    });
  });

  // dataの中からドライバーが設定されていないものを抽出
  const noDriverData = data.filter((inst) => !inst.driver_id);

  // noDriverDataをdataから削除
  data = data.filter((inst) => !noDriverData.includes(inst));

  // keyでグループ化
  const groupData = noDriverData.reduce(
    (acc, cur) => {
      (acc[cur.key] = acc[cur.key] || []).push(cur);
      return acc;
    },
    {} as Record<string, tCustomInstCard[]>
  );

  // グループの並び順をkeyの昇順に変更
  const groupDataKeys = Object.keys(groupData).sort();
  const groupDataSorted = groupDataKeys.reduce(
    (acc, key) => {
      acc[key] = groupData[key];
      return acc;
    },
    {} as Record<string, tCustomInstCard[]>
  );

  // グループ毎にnewTableRowDataにデータを追加
  Object.keys(groupDataSorted).forEach((key) => {
    // 卸日がdateのものを抽出
    const dateData = groupDataSorted[key].filter((inst) =>
      isSameDate(inst.end_datetime, changeDateToStrDay(date))
    );

    if (!dateData || dateData.length === 0) {
      return;
    }

    newNoDriverData.push({
      driver: 0,
      data: dateData,
      dataBefore1: [],
      dataBefore2: [],
      dataBefore3: [],
      dataAfter: [],
      key: key,
    });
  });

  // dataにまだデータが残っている場合（ドライバー付き）は追加
  if (data.length > 0) {
    // ドライバー単位でグループ化
    const driverGroupData = data.reduce(
      (acc, cur) => {
        (acc[cur.driver_id || 0] = acc[cur.driver_id || 0] || []).push(cur);
        return acc;
      },
      {} as Record<number, tCustomInstCard[]>
    );

    // driver_idの順に並び替える
    const driverGroupDataKeys = Object.keys(driverGroupData).sort();
    const driverGroupDataSorted = driverGroupDataKeys.reduce(
      (acc, key) => {
        acc[Number(key)] = driverGroupData[Number(key)];
        return acc;
      },
      {} as Record<number, tCustomInstCard[]>
    );

    Object.keys(driverGroupDataSorted).forEach((driverID) => {
      const [before3, brfore2, before1, nowDay, after] = dataSortSameDate(
        date,
        driverGroupDataSorted[Number(driverID)]
      );

      newDriverData.push({
        driver: Number(driverID),
        data: nowDay,
        dataBefore1: before1,
        dataBefore2: brfore2,
        dataBefore3: before3,
        dataAfter: after,
        key: `${date.toISOString()}-driver-${driverID}`,
      });
    });
  }

  // newDriverDataの数だけdataがからのnewTableRowDataを削除する
  if (newDriverData.length > 0) {
    log.debug('newDriverData', newDriverData);
    for (let i = 0; i < newDriverData.length; i++) {
      if (newTableRowData[newTableRowData.length - 1].data.length <= 0) {
        newTableRowData.splice(newTableRowData.length - 1, 1);
      } else {
        break;
      }
    }
  }

  setTableRowData([...newTableRowData, ...newNoDriverData, ...newDriverData]);
};

/**
 * 日別にデータを分ける
 * @param date
 * @param data
 * @returns
 */
const dataSortSameDate = (date: Date, data: tCustomInstCard[]) => {
  const dateData = data.filter((inst) =>
    isSameDate(inst.end_datetime, changeDateToStrDay(date))
  );

  // 卸日がdate - 1のものを抽出
  const dateBefore1Data = data.filter((inst) =>
    isSameDate(
      inst.end_datetime,
      mathDateTime(date, [0, 0, -1, 0, 0, 0]).toLocaleDateString()
    )
  );

  // 卸日がdate - 2のものを抽出
  const dateBefore2Data = data.filter((inst) =>
    isSameDate(
      inst.end_datetime,
      mathDateTime(date, [0, 0, -2, 0, 0, 0]).toLocaleDateString()
    )
  );

  // 卸日がdate - 3のものを抽出
  const dateBefore3Data = data.filter((inst) =>
    isSameDate(
      inst.end_datetime,
      mathDateTime(date, [0, 0, -3, 0, 0, 0]).toLocaleDateString()
    )
  );

  //  卸日がdate + 1のものを抽出
  const dateAfterData = data.filter((inst) =>
    isSameDate(
      inst.end_datetime,
      mathDateTime(date, [0, 0, 1, 0, 0, 0]).toLocaleDateString()
    )
  );

  return [
    dateBefore3Data,
    dateBefore2Data,
    dateBefore1Data,
    dateData,
    dateAfterData,
  ];
};

/**
 * ドライバー選択モーダル
 * @param param0
 * @returns
 */
const ModalDriver = ({
  stateDrivers,
  stateOpen,
}: {
  stateDrivers: [ListItem[], React.Dispatch<React.SetStateAction<ListItem[]>>];
  stateOpen: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}) => {
  const { drivers } = useContext(MstDataContext);
  const [open, setOpen] = stateOpen;
  const [val, set] = stateDrivers;
  const [targetDriver, setTargetDriver] = useState<ListItem[]>(val);

  log.debug('ModalDriver', val);
  log.debug('ModalDriver', targetDriver);
  /**
   * モーダルを閉じる処理
   */
  const handleClose = () => {
    // 選択内容を反映させる
    set(targetDriver);
    setOpen(false);
  };

  return (
    <Modal
      title={'ドライバー選択'}
      open={open}
      onClose={handleClose}
      actions={<SecondButton label="閉じる" onClick={handleClose} />}
      width={'md'}
    >
      <Loading flg={!drivers} />
      {drivers && (
        <Grid container spacing={2}>
          {drivers.map((driver) => {
            return (
              <Grid item key={driver.id}>
                <SecondButton
                  className={[
                    targetDriver.some((item) => item.id === driver.id)
                      ? 'selected'
                      : '',
                  ]}
                  label={driver.label}
                  onClick={() => {
                    if (targetDriver.includes(driver)) {
                      setTargetDriver((prev) => {
                        return prev.filter((item) => item !== driver);
                      });
                    } else {
                      setTargetDriver((prev) => {
                        return [...prev, driver];
                      });
                    }
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Modal>
  );
};
