import { Box, Grid } from '@mui/material';
import { DeleteButton, PrimaryButton, SecondButton } from 'atoms/Button';
import * as CustomGrid from 'atoms/Grid';
import Modal from 'atoms/Modal';
import ModalEdit from 'components/customInvocie/ModalEdit';
import ErrModal from 'components/error/Modal';
import * as AutocompleteItems from 'components/input/AutoComplete';
import { InvoicePreview } from 'components/invoice/Button';
import {
  deleteCustomInvoiceEdit,
  makeInvoice,
  storeCustomInvoiceEdit,
} from 'functions/api/customInvoice';
import { changeToState } from 'functions/error/';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProviderCustom } from 'themes/Base';
import { tErrMsg } from 'types/index';
import { tCustomInvoiceEdit, tInvoice } from 'types/invoice';

export const NavigateInput = () => {
  const navigate = useNavigate();

  const [cId, setCId] = useState<number>();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    navigate(`/paperwork/custom-invoice/edit/${cId}`);
  };

  const cbValueChange = (val: any) => {
    setCId(val.id);
  };

  return (
    <ThemeProviderCustom>
      <Modal
        width={'sm'}
        title="取引先選択"
        actions={
          <>
            <PrimaryButton label="新規" onClick={handleClick} />
          </>
        }
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box>
          <Grid container spacing={2}>
            <CustomGrid.GridItem
              size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            >
              <AutocompleteItems.ClientCompany
                label={'取引先'}
                value={{ id: cId || null, label: '' }}
                cbValueChange={cbValueChange}
              />
            </CustomGrid.GridItem>
          </Grid>
        </Box>
      </Modal>
      <PrimaryButton label="新規" onClick={() => setOpen(true)} />
    </ThemeProviderCustom>
  );
};

interface InsertProps {
  callbackSuccess?: ((data: tCustomInvoiceEdit) => void) | undefined;
  callbackError?: (() => void) | undefined;
  data: tCustomInvoiceEdit;
}

export const Insert = ({
  callbackSuccess = undefined,
  callbackError = undefined,
  data,
}: InsertProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    if (data.id) {
      // idを削除
      delete data.id;
    }

    const cbs = (data: tCustomInvoiceEdit) => {
      if (callbackSuccess) {
        callbackSuccess(data);
      }
    };

    const cbe = (err: any) => {
      setValidationMsg(changeToState(err));
      setShowModal(true); // モーダルを表示
      if (callbackError) callbackError();
    };

    storeCustomInvoiceEdit(cbs, cbe, data);
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="登録" onClick={handleClick} />
    </>
  );
};

/* 明細の更新はとりあえずなし
export const Update = ({
  callbackSuccess,
  callbackError,
  data,
}: InsertProps) => {
  const handleClick = () => {
    updateCustomInvoiceEdit(callbackSuccess, callbackError, data);
  };

  return <PrimaryButton label="更新" onClick={handleClick} />;
};
*/

interface DeleteProps {
  callbackSuccess?: ((data: tCustomInvoiceEdit) => void) | undefined;
  callbackError?: ((err: any) => void) | undefined;
  id: number;
}

export const Delete = ({
  callbackSuccess = undefined,
  callbackError = undefined,
  id,
}: DeleteProps) => {
  const handleClick = () => {
    if (id) {
      const cbs = (data: tCustomInvoiceEdit) => {
        if (callbackSuccess) {
          callbackSuccess(data);
        }
      };
      const cbe = (err: any) => {
        if (callbackError) {
          callbackError(err);
        }
      };
      deleteCustomInvoiceEdit(cbs, cbe, id);
    }
  };

  return <DeleteButton label="削除" onClick={handleClick} />;
};

interface MakeInvoiceProps {
  callbackSuccess?: ((data: tInvoice) => void) | undefined;
  callbackError?: (() => void) | undefined;
  ids: tCustomInvoiceEdit['id'][];
  iv: tInvoice;
}

export const MakeInvoice = ({
  callbackSuccess = undefined,
  callbackError = undefined,
  ids,
  iv,
}: MakeInvoiceProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});
  const [ivId, setIvId] = useState<number | undefined>(undefined);
  const [showPdf, setShowPdf] = useState(false);

  const handleClick = () => {
    if (ids.length === 0) {
      alert('請求書を作成する明細が選択されていません');
      return;
    }

    const cbs = (data: tInvoice) => {
      // 請求書を発行するかを確認
      if (
        window.confirm(
          '請求書データ作成に成功しました。\nこのまま請求書を発行しますか？'
        )
      ) {
        setShowPdf(true);
        setIvId(data.id);
      } else {
        setShowPdf(false);
      }
      if (callbackSuccess) {
        callbackSuccess(data);
      }
    };

    const cbe = (err: any) => {
      setValidationMsg(changeToState(err));
      setShowModal(true); // モーダルを表示
      if (callbackError) callbackError();
    };

    makeInvoice({ ids: ids, invoice: iv }, cbs, cbe);
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      {showPdf && <InvoicePreview id={ivId} defFlgShow={true} />}
      <PrimaryButton label="請求書作成" onClick={handleClick} />
    </>
  );
};

interface ModalEditProps {
  cId: number;
  callbackSuccess: (() => void) | undefined;
}
export const VieModalEdit = ({ cId, callbackSuccess }: ModalEditProps) => {
  const [flgOpen, setFlgOpen] = useState(false);

  const handleClick = () => {
    setFlgOpen(true);
  };

  const handleClose = () => {
    setFlgOpen(false);
    if (callbackSuccess) {
      callbackSuccess();
    }
  };

  return (
    <>
      <ModalEdit cId={cId} flgOpen={flgOpen} cbOnClose={handleClose} />
      <SecondButton label="明細登録" onClick={handleClick} />
    </>
  );
};
