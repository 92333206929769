import AutoCompleteItem from 'atoms/AutoComplete';
import Loading from 'atoms/Loading';
import { prefectures } from 'const/index';
import { MstDataContext } from 'contexts/Mst';
import { getCompanyPersonList } from 'functions/api/mst';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ItemAutoCompleteProps, List } from 'types';
import { tCompany } from 'types/company';
import { tVehicle } from 'types/mst';

/**
 * 取引先選択
 * @param param0
 * @returns
 */
export const ClientCompany = ({
  label = '取引先',
  value,
  cbValueChange,
}: ItemAutoCompleteProps) => {
  const { loading, companies } = React.useContext(MstDataContext);

  if (loading) {
    return <Loading flg={true} />;
  }

  const options = companies?.map((company) => ({
    id: company.id,
    label: company.abbreviation,
  })) as List;

  const selectedOption = options?.find((option) => option.id === value.id);

  console.log('selectedOption', selectedOption);

  return (
    <AutoCompleteItem
      label={label}
      name="c_id"
      value={selectedOption || { id: value.id, label: value.label }}
      items={options}
      cbValueChange={cbValueChange}
      flgCode={true}
    />
  );
};

/**
 * お客様担当
 * @param param0
 * @returns
 */
export const ClientPerson = ({
  label = '担当',
  value,
  c_id,
  cbValueChange,
}: ItemAutoCompleteProps & { c_id: tCompany['id'] }) => {
  const [options, setOptions] = useState<List>([]);

  useEffect(() => {
    if (!c_id) {
      setOptions([]);
    } else {
      getCompanyPersonList(c_id).then((res) => {
        setOptions(res.data as List);
      });
    }
  }, [c_id]);

  return (
    <AutoCompleteItem
      name="p_id"
      label={label}
      items={options}
      value={value}
      cbValueChange={cbValueChange}
    />
  );
};

/**
 * 荷種選択
 * @param param0
 * @returns
 */
export const LuggageID = ({
  value,
  label,
  cbValueChange,
}: ItemAutoCompleteProps) => {
  const { loading, luggages } = useContext(MstDataContext);
  if (loading) {
    return <Loading flg={true} />;
  }

  const options = luggages?.map((luggage) => ({
    id: luggage.id,
    label: luggage.name,
  })) as List;

  return (
    <AutoCompleteItem
      name="l_id"
      label={label}
      items={options}
      value={value}
      cbValueChange={cbValueChange}
      freeSolo={true}
    />
  );
};

export const Prefectures = ({
  value,
  cbValueChange,
}: ItemAutoCompleteProps) => {
  const { loading } = useContext(MstDataContext);
  if (loading) {
    return <Loading flg={true} />;
  }

  const options = prefectures?.map((prefecture) => ({
    id: prefecture.id,
    label: prefecture.label,
  })) as List;

  return (
    <AutoCompleteItem
      name="p_id"
      label={'都道府県'}
      items={options}
      value={value}
      cbValueChange={cbValueChange}
    />
  );
};

/**
 * 車両
 * @param param0
 * @returns
 */
export const Vehicle = ({
  value,
  label,
  cbValueChange,
  vt_id = undefined,
  freeSolo = true,
}: ItemAutoCompleteProps & {
  vt_id?: tVehicle['vt_id'];
}) => {
  const { loading, vehicles } = useContext(MstDataContext);
  if (loading || !vehicles) {
    return <Loading flg={true} />;
  }

  console.log('vt_id', vt_id);

  // 車両種類に該当する車両を抽出
  const options = useMemo(
    () =>
      vehicles
        .filter((vehicle) => !vt_id || vehicle.vt_id === vt_id)
        .map((vehicle) => ({
          id: vehicle.id,
          label: vehicle.plate,
        })) as List,
    [vehicles, vt_id]
  );

  return (
    <AutoCompleteItem
      name="v_id"
      label={label}
      items={options}
      value={value}
      cbValueChange={cbValueChange}
      freeSolo={freeSolo}
    />
  );
};

/**
 * 被牽引車
 * @param param0
 * @returns
 */
export const Trailer = ({
  value,
  label,
  cbValueChange,
  freeSolo = true,
}: ItemAutoCompleteProps) => {
  const { loading, trailer } = useContext(MstDataContext);
  if (loading || !trailer) {
    return <Loading flg={true} />;
  }

  const options: List = useMemo(
    () =>
      trailer.map((v) => ({
        id: v.id || null,
        label: v.plate,
      })),
    [trailer]
  );

  return (
    <AutoCompleteItem
      name="vt_id"
      label={label}
      items={options}
      value={value}
      cbValueChange={cbValueChange}
      freeSolo={freeSolo}
    />
  );
};
