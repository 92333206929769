import { KeyDefined } from 'const/index';

export function adjustArrayLength<T>(
  array: T[],
  desiredLength: number,
  fillValue: T
): T[] {
  if (array.length > desiredLength) {
    // 要素数を減らす
    return array.slice(0, desiredLength);
  } else if (array.length < desiredLength) {
    // 要素数を増やす
    return array.concat(Array(desiredLength - array.length).fill(fillValue));
  } else {
    // 要素数が既に指定の長さと同じ場合
    return array;
  }
}

/**
 * 指定キーでグループ化する
 * @param array
 * @param key
 * @returns
 */
export function groupBy<T, K extends keyof T>(
  array: T[],
  key: K
): { [key: string]: T[] } {
  return array.reduce(
    (acc, item) => {
      const groupKey = item[key] ?? KeyDefined; // null/undefined の場合は "unknown"
      const keyString = String(groupKey); // キーを文字列に変換

      if (!acc[keyString]) {
        acc[keyString] = [];
      }
      acc[keyString].push(item);
      return acc;
    },
    {} as { [key: string]: T[] }
  );
}

/**
 * 連想配列の配列の指定キーの合計を算出
 * @param array
 * @param key
 * @returns
 */
export const reduceSum = <T extends Record<string, any>>(
  array: T[],
  key: keyof T
): number => {
  if (!Array.isArray(array)) return 0; // 配列でなければ0を返す

  return array.reduce((sum: number, item: T) => {
    const value = item[key];
    return typeof value === 'number' ? sum + value : sum;
  }, 0);
};

/**
 * 連想配列の配列の指定キーの最大値を算出
 * @param array
 * @param key
 * @returns
 */
export const reduceMax = <T extends Record<string, any>>(
  array: T[],
  key: keyof T
): number | undefined => {
  const validNumbers = array
    .map((item) => item[key])
    .filter((value) => typeof value === 'number' && !isNaN(value));

  return validNumbers.length > 0 ? Math.max(...validNumbers) : undefined; // ✅ 数値がない場合は `undefined`
};

// ✅ 配列を30行ごとに分割する関数
export const chunkArray = <T>(array: T[], size: number): T[][] => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
    array.slice(index * size, index * size + size)
  );
};

/**
 * 連想配列の値の合計
 * @param array
 * @param key
 * @returns
 */
export const sumRecordValues = (record: Record<string, number>): number => {
  return Object.values(record).reduce((acc, value) => acc + Number(value), 0);
};
